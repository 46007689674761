import React, { Component } from 'react';
import { Styles } from '../styles/courseCategory.js';
import {firestore, storage} from "../../../components/firebase/fbApp";

class CourseCategory extends Component {

    constructor(){
        super();
        this.state = {
            univ: 'ITAM',
            deps: []
        }
    }

    componentDidMount() {
        firestore.doc(`universidades/${this.state.univ}`)
            .get()
            .then(resp => {
                this.setState({
                    deps: resp.data().departamento
                })
            })
    }


    

    render() {
        return (
            <Styles>
                {/* Course Tag */}
                <div className="course-category">
                    <h5>Departamento</h5>
                    <ul className="category-item list-unstyled">
                        {this.state.deps ? (
                                this.state.deps.map(dep => <li className="check-btn" style={{cursor:'pointer'}} onClick={(e)=> {
                                    this.props.changeFilter({dep}.dep)}}>
                                    {dep}
                                </li>)
                            ) : (<></>)}
                        <li className="check-btn" style={{cursor:'pointer'}} onClick={(e)=> {
                            this.props.deactivateFilter()}}>
                            Todos
                        </li>
                    </ul>
                </div>
            </Styles>
        )
    }
}

export default CourseCategory
