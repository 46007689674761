import React, {Component, useContext, useEffect, useState} from 'react';
import {Container, Row, Col, Tab, Nav, Modal, Button} from 'react-bootstrap';
import HeaderTwo from '../../components/HeaderTwo';
import { BreadcrumbBox } from '../../components/common/Breadcrumb';
import ReviewForm from './components/ReviewForm';
import FooterTwo from '../../components/FooterTwo';
import { Styles } from './styles/course.js';
import {firestore, storage} from "../../components/firebase/fbApp";
import ReactLoading from "react-loading";
import {Link} from "react-router-dom";
import App from "../../stripe/app";
import {UserContext} from "../../providers/UserProvider";
import {providerContextTypes} from "react-stripe-elements/lib/components/Provider";
import BlogDetails from '../blog/BlogDetails';
import CourseRecoms from './CourseRecoms';

const getUrl = async (img) => {
    await img
    try {
        const url =   storage.ref().child(img).getDownloadURL()
        return await url
    } catch (error) {
        console.log(error)
        return (process.env.PUBLIC_URL + '/assets/images/about-1.jpg')
    }

}

const getVideo = async (video) => {
    await video
    return video

}

const getCoursesDetails = async (handle,handle2) => {


        const data = await firestore.collection('cursos').where('courseLink', '==', handle).get()

        let parciales = []

        for(let item in data.docs[0].data()["Parciales"]){
            if(data.docs[0].data()["Parciales"][item]["productUrl"] === handle2) {
                await getUrl(data.docs[0].data()["Parciales"][item].imgURL)
                    .then((url) => {
                            const tmp = {
                                id: data.docs[0].data()["Parciales"][item].id,
                                productImg: url,
                                productTitle: data.docs[0].data()["Parciales"][item].Titulo,
                                productUrl: data.docs[0].data()["Parciales"][item].productUrl,
                                price: data.docs[0].data()["Parciales"][item].price,
                                longTitle: data.docs[0].data()["Parciales"][item].longTitle,
                                tutor: data.docs[0].data().authorName,
                                categoria: data.docs[0].data().category,
                                stars:data.docs[0].data().stars,
                                descripcion: data.docs[0].data()["Parciales"][item].descripcion,
                                DuracionDeVideos: data.docs[0].data()["Parciales"][item].DuracionDeVideos,
                                Videos: data.docs[0].data()["Parciales"][item].Videos,
                                Quizes: data.docs[0].data()["Parciales"][item].Quizes,
                                ExamenesResueltos: data.docs[0].data()["Parciales"][item].ExamenesResueltos,
                                curriculumOverview: data.docs[0].data()["Parciales"][item].curriculumOverview,

                            }
                            parciales.push(tmp)
                        }
                    )
            }
        }
        return parciales[0]


}

const getContent =  async (materia,parcial) => {
  console.log("materia","parcial",(materia+"-"+parcial))
    const data = await firestore.collection('parciales').doc((materia+"-"+parcial)).collection("contenido").get()

  console.log(data)
    let content=[]
    data.docs.sort((item1,item2)=>{
        return (item1.data().orden - item2.data().orden)
    }).map((item)=>{
        let tmp={
            titulo:item.id,
            video:item.data().video,
            orden: item.data().orden,
            tiempo: item.data().tiempo,
            quiz: item.data().quiz,
            N: item.data().N,

        }

        content.push(tmp)
        return tmp

    })
    return content

}

const getComments = async (handle, handle2) => {
    let aux = handle+'-'+handle2
    console.log(aux)
    const data = await firestore.collection(`parciales/${aux}/comentarios`).where("date", ">", 0).orderBy("date", "desc").get()
    console.log(data)
    if(!data.empty){
        // Si hay comentarios
        // Ahi sale el primer comentario
        console.log(data.docs[0].data())
        return data.docs 
    }else{
        // No hay comentarios
        return []
    }
}

const getRecos = async (handle, handle2) => {
    let aux = handle+'-'+handle2
    console.log(aux)
    const data = await firestore.collection(`parciales/${aux}/recomendados`).get()
    console.log(data)
    if(!data.empty){
        // Si hay comentarios
        // Ahi sale el primer comentario
        // console.log(data.docs[0].data())
        return data.docs 
    }else{
        // No hay comentarios
        return []
    }
}

const checkBuy = async (parcial) => {
  var uid = localStorage.getItem("Verification")
  ///var uid ="aUdDbuFoJiOo4nqQHUqlEvEClRc2"
  console.log(uid)
  if(uid != null){
    const userRef = firestore.collection('users').doc(uid);
    const fechas = await firestore.doc('fechas/fechas').get();
    const snapshot = await userRef.get();
    const inicio = fechas.data().inicio
    const fin = fechas.data().fin
    //console.log(inicio)
    //console.log(fin)
    console.log("snapshot",snapshot.data())
    var date = undefined
    try{
      date = snapshot.data()["comprado"]["data"][parcial];
    }
    catch (e){
      console.log(e)
    }

    console.log("date",date,typeof(date))
    if (typeof(date) != "undefined") {
      if (date < fin && date > inicio ){
        console.log("lo tiene")
        return true
      }
      else {
        console.log((date < fin) , (date > inicio))
        console.log("vencido")
        return false
      }

    }
    else{
      console.log("undefined date")
      return false
    }

  }
  else {return false}


}



class CourseDetails extends Component {
    constructor(props) {
        super(props);
        this.state={
            data: {},
            loading: true,
            curriculum:[],
            error: false,
            comprar: false,
            videoGratis:"",
            comments:[],
            hayComments: false,
            recos:[]
        }
    }

    componentDidMount(){
        
        const {handle,handle2} = this.props.match.params
        
        getComments(handle,handle2).then( res => {
            if(res.length > 0){
                this.setState(
                    {
                        hayComments: true,
                        comments: res
                    })
            }
        })

        getRecos(handle,handle2).then( res => {
            if(res.length > 0){
                this.setState({recos: res})
            }
        })

        getCoursesDetails(handle,handle2).then( (res) => {
            this.setState(
               {data: res,}
            )
        }).catch((e)=>{
            console.log(e,"e1")
            this.setState({
                error:true,
                loading:false,
            })
        })

        getContent(handle,handle2).then((res)=>{
            getVideo(res[0].video).then((video)=>{
                this.setState({
                    curriculum:res,
                    loading:false,
                    videoGratis:video,
                })
            })



        }).catch((e)=>{
            console.log(e,"e2")
            this.setState({
                error:true,
                loading:false,
            })
        })

        checkBuy((handle+"-"+handle2)).then((res)=>
        {
            this.setState({
              comprar: res
            })
        })

        const courseButton = document.querySelectorAll(".course-button");
        courseButton.forEach(button => {
            button.addEventListener("click", () => {
                button.classList.toggle("active");
                const content = button.nextElementSibling;

                if (button.classList.contains("active")) {
                    content.className = "course-content show";
                    content.style.maxHeight = content.scrollHeight + "px";
                } else {
                    content.className = "course-content";
                    content.style.maxHeight = "0";
                }
            });
        });
    }


    render(){
        const {handle,handle2} = this.props.match.params
        return (
            <div className="main-wrapper course-details-page" >

                {/* Header 2 */}
                < HeaderTwo />

                {/* Breadcroumb */}
                < BreadcrumbBox title={handle + " / "+ handle2} />

                <Styles>
                    {/* Course Details */}
                    <section className="course-details-area">
                        <Container>
                            {this.state.loading && !this.state.error &&
                            <ReactLoading type="bubbles" color="#11B67A" height={'10%'} width={'10%'} />
                            }
                            {(handle2 === "coming-soon") && !this.state.loading &&
                            (<div>
                                <h1> Disculpa el inconveniente</h1>
                                <p>Estamos terminando de definir algunos detalles con tu curso, para que esté actualizado con el temario del curso, pero no te preocupes, en unos días contarás con todo el material, videos y aprendizajes de la materia!</p>

                            </div>)
                            }
                            {!this.state.loading && this.state.error && (handle2 !== "coming-soon")&&
                            (<div>
                                <h1>Intente de Nuevo</h1>
                                <h2>Ocurrio un Error</h2>
                                <p>Si el problema continua contactanos.</p>
                            </div>)
                            }
                            { (!this.state.error && !this.state.loading) && (
                                <Row>
                                    <Col lg="9" md="8" sm="12">
                                        <div className="course-details-top">
                                            <div className="heading">
                                                <h4>{this.state.data.longTitle}</h4>
                                            </div>
                                            <div className="course-top-overview">
                                                <div className="d-flex overviews">
                                                    <div className="author">
                                                        <div className="author-name">
                                                            <h6>Tutor</h6>
                                                            <p>{this.state.data.tutor}</p>
                                                        </div>
                                                    </div>
                                                    <div className="category">
                                                        <h6>Categoría</h6>
                                                        <p>{this.state.data.categoria}</p>
                                                    </div>
                                                    <div className="rating">
                                                        <h6>Rating</h6>
                                                        <ul className="list-unstyled list-inline">
                                                            <li className="list-inline-item"><i className="las la-star"></i></li>
                                                            <li className="list-inline-item"><i className="las la-star"></i></li>
                                                            <li className="list-inline-item"><i className="las la-star"></i></li>
                                                            <li className="list-inline-item"><i className="las la-star"></i></li>
                                                            <li className="list-inline-item"><i className="las la-star-half-alt"></i>
                                                            </li>
                                                            <li className="list-inline-item">{this.state.data.stars}</li>
                                                        </ul>
                                                    </div>
                                                    <div className="price">
                                                        <h6>Precio</h6>
                                                        <p>${this.state.data.price}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="course-details-banner">
                                                <img src={this.state.data.productImg} alt="" className="img-fluid" />
                                            </div>
                                            <div className="course-tab-list">
                                                <Tab.Container defaultActiveKey="curriculum">
                                                    <Nav className="flex-column">

                                                        <Nav.Item>
                                                            <Nav.Link eventKey="curriculum">Curriculum</Nav.Link>
                                                        </Nav.Item>
                                                        {false &&(<Nav.Item>
                                                            <Nav.Link eventKey="instructor">Instructor</Nav.Link>
                                                        </Nav.Item>)}
                                                        {false &&(<Nav.Item>
                                                            <Nav.Link eventKey="review">Reviews</Nav.Link>
                                                        </Nav.Item>)}

                                                        <Nav.Item>
                                                            <Nav.Link eventKey="videoprueba">Video de Prueba</Nav.Link>
                                                        </Nav.Item>

                                                        <Nav.Item>
                                                            <Nav.Link eventKey="coments">Comentarios</Nav.Link>
                                                        </Nav.Item>
                                                    </Nav>
                                                    <Tab.Content>
                                                        <Tab.Pane eventKey="coments" className="review-tab">
                                                            <BlogDetails arreComments = {this.state.comments} 
                                                                isComprado={this.state.comprar}
                                                                handles= {handle+'-'+handle2}/>
                                                        </Tab.Pane>
                                                        <Tab.Pane eventKey="overview" className="overview-tab">

                                                            <div className="course-share">
                                                                <h5>Share This Course</h5>
                                                                <ul className="social list-unstyled list-inline">
                                                                    <li className="list-inline-item"><a href={process.env.PUBLIC_URL + "/"}><i className="fab fa-facebook-f"></i></a></li>
                                                                    <li className="list-inline-item"><a href={process.env.PUBLIC_URL + "/"}><i className="fab fa-twitter"></i></a></li>
                                                                    <li className="list-inline-item"><a href={process.env.PUBLIC_URL + "/"}><i className="fab fa-linkedin-in"></i></a></li>

                                                                </ul>
                                                            </div>
                                                        </Tab.Pane>
                                                        <Tab.Pane eventKey="curriculum" className="curriculum-tab">
                                                            <div className="course-curriculum">

                                                                <p>
                                                                    {this.state.data.curriculumOverview}
                                                                </p>
                                                            </div>
                                                            <div className="course-element">
                                                                <h5>Contenido del Curso</h5>
                                                                <div className="course-item">
                                                                    <div className="course-content show">
                                                                        <ul className="list-unstyled">
                                                                            {
                                                                                !this.state.loading && this.state.curriculum.map((item)=>{
                                                                                    let link = "/course-video/"
                                                                                    console.log(item.quiz,"link")
                                                                                    if(item.quiz){
                                                                                        link="/quiz/"
                                                                                    }
                                                                                    return (
                                                                                        <Link to={link+handle+'/'+handle2+'/'+item.orden}  key={item.orden}>
                                                                                            <li  key={item.N}>
                                                                                                <span className="play-icon"><i className="las la-play"></i> Tema: {item.N}</span>
                                                                                                <span className="lecture-title">{item.titulo}</span>
                                                                                                <span className="lecture-duration">{item.tiempo}</span>
                                                                                            </li>
                                                                                        </Link>

                                                                                    )
                                                                                })
                                                                            }
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Tab.Pane>
                                                        {false && (<Tab.Pane eventKey="instructor" className="instructor-tab">
                                                            <h5>Course Instructors</h5>
                                                            <div className="instructor-item">
                                                                <Row>
                                                                    <Col md="4">
                                                                        <div className="instructor-img">
                                                                            <img
                                                                                src={process.env.PUBLIC_URL + `/assets/images/instructor-1.jpg`}
                                                                                alt="" className="img-fluid"/>
                                                                        </div>
                                                                    </Col>
                                                                    <Col md="8">
                                                                        <div className="instructor-content">
                                                                            <div className="instructor-box">
                                                                                <div
                                                                                    className="top-content d-flex justify-content-between">
                                                                                    <div className="instructor-name">
                                                                                        <h6>Mark Shadow</h6>
                                                                                        <p>Senior Lecturer</p>
                                                                                    </div>
                                                                                    <div className="instructor-social">
                                                                                        <ul className="social list-unstyled list-inline">
                                                                                            <li className="list-inline-item">
                                                                                                <a href={process.env.PUBLIC_URL + "/"}><i
                                                                                                    className="fab fa-facebook-f"></i></a>
                                                                                            </li>
                                                                                            <li className="list-inline-item">
                                                                                                <a href={process.env.PUBLIC_URL + "/"}><i
                                                                                                    className="fab fa-twitter"></i></a>
                                                                                            </li>
                                                                                            <li className="list-inline-item">
                                                                                                <a href={process.env.PUBLIC_URL + "/"}><i
                                                                                                    className="fab fa-linkedin-in"></i></a>
                                                                                            </li>
                                                                                            <li className="list-inline-item">
                                                                                                <a href={process.env.PUBLIC_URL + "/"}><i
                                                                                                    className="fab fa-youtube"></i></a>
                                                                                            </li>
                                                                                        </ul>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="instructor-desk">
                                                                                    <p>Lorem ipsum dolor sit amet
                                                                                        consectetur adipisicing elit.
                                                                                        Quae perferendis delectus
                                                                                        voluptate reiciendis animi nisi
                                                                                        nemo tenetur sequi cum
                                                                                        laudantium sit totam libero
                                                                                        quasi ducimus accusantium
                                                                                        numquam eaque.</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                            <div className="instructor-item">
                                                                <Row>
                                                                    <Col md="4">
                                                                        <div className="instructor-img">
                                                                            <img
                                                                                src={process.env.PUBLIC_URL + `/assets/images/instructor-2.jpg`}
                                                                                alt="" className="img-fluid"/>
                                                                        </div>
                                                                    </Col>
                                                                    <Col md="8">
                                                                        <div className="instructor-content">
                                                                            <div className="instructor-box">
                                                                                <div
                                                                                    className="top-content d-flex justify-content-between">
                                                                                    <div className="instructor-name">
                                                                                        <h6>Katrin Kay</h6>
                                                                                        <p>Senior Lecturer</p>
                                                                                    </div>
                                                                                    <div className="instructor-social">
                                                                                        <ul className="social list-unstyled list-inline">
                                                                                            <li className="list-inline-item">
                                                                                                <a href={process.env.PUBLIC_URL + "/"}><i
                                                                                                    className="fab fa-facebook-f"></i></a>
                                                                                            </li>
                                                                                            <li className="list-inline-item">
                                                                                                <a href={process.env.PUBLIC_URL + "/"}><i
                                                                                                    className="fab fa-twitter"></i></a>
                                                                                            </li>
                                                                                            <li className="list-inline-item">
                                                                                                <a href={process.env.PUBLIC_URL + "/"}><i
                                                                                                    className="fab fa-linkedin-in"></i></a>
                                                                                            </li>
                                                                                            <li className="list-inline-item">
                                                                                                <a href={process.env.PUBLIC_URL + "/"}><i
                                                                                                    className="fab fa-youtube"></i></a>
                                                                                            </li>
                                                                                        </ul>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="instructor-desk">
                                                                                    <p>Lorem ipsum dolor sit amet
                                                                                        consectetur adipisicing elit.
                                                                                        Quae perferendis delectus
                                                                                        voluptate reiciendis animi nisi
                                                                                        nemo tenetur sequi cum
                                                                                        laudantium sit totam libero
                                                                                        quasi ducimus accusantium
                                                                                        numquam eaque.</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                            <div className="instructor-item">
                                                                <Row>
                                                                    <Col md="4">
                                                                        <div className="instructor-img">
                                                                            <img
                                                                                src={process.env.PUBLIC_URL + `/assets/images/instructor-3.jpg`}
                                                                                alt="" className="img-fluid"/>
                                                                        </div>
                                                                    </Col>
                                                                    <Col md="8">
                                                                        <div className="instructor-content">
                                                                            <div className="instructor-box">
                                                                                <div
                                                                                    className="top-content d-flex justify-content-between">
                                                                                    <div className="instructor-name">
                                                                                        <h6>David Show</h6>
                                                                                        <p>Senior Lecturer</p>
                                                                                    </div>
                                                                                    <div className="instructor-social">
                                                                                        <ul className="social list-unstyled list-inline">
                                                                                            <li className="list-inline-item">
                                                                                                <a href={process.env.PUBLIC_URL + "/"}><i
                                                                                                    className="fab fa-facebook-f"></i></a>
                                                                                            </li>
                                                                                            <li className="list-inline-item">
                                                                                                <a href={process.env.PUBLIC_URL + "/"}><i
                                                                                                    className="fab fa-twitter"></i></a>
                                                                                            </li>
                                                                                            <li className="list-inline-item">
                                                                                                <a href={process.env.PUBLIC_URL + "/"}><i
                                                                                                    className="fab fa-linkedin-in"></i></a>
                                                                                            </li>
                                                                                            <li className="list-inline-item">
                                                                                                <a href={process.env.PUBLIC_URL + "/"}><i
                                                                                                    className="fab fa-youtube"></i></a>
                                                                                            </li>
                                                                                        </ul>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="instructor-desk">
                                                                                    <p>Lorem ipsum dolor sit amet
                                                                                        consectetur adipisicing elit.
                                                                                        Quae perferendis delectus
                                                                                        voluptate reiciendis animi nisi
                                                                                        nemo tenetur sequi cum
                                                                                        laudantium sit totam libero
                                                                                        quasi ducimus accusantium
                                                                                        numquam eaque.</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                        </Tab.Pane>)}
                                                        {false && (<Tab.Pane eventKey="review" className="review-tab">
                                                            <Row>
                                                                <Col md="12">
                                                                    <div className="review-comments">
                                                                        <h5>Course Reviews</h5>
                                                                        <div className="comment-box d-flex">
                                                                            <div className="comment-image">
                                                                                <img
                                                                                    src={process.env.PUBLIC_URL + `/assets/images/testimonial-2.jpg`}
                                                                                    alt=""/>
                                                                            </div>
                                                                            <div className="comment-content">
                                                                                <div
                                                                                    className="content-title d-flex justify-content-between">
                                                                                    <div className="comment-writer">
                                                                                        <h6>Mark Shadow</h6>
                                                                                        <p>Mar 26, 2020 | 06:30pm</p>
                                                                                        <ul className="list-unstyled list-inline">
                                                                                            <li className="list-inline-item">
                                                                                                <i className="las la-star"></i>
                                                                                            </li>
                                                                                            <li className="list-inline-item">
                                                                                                <i className="las la-star"></i>
                                                                                            </li>
                                                                                            <li className="list-inline-item">
                                                                                                <i className="las la-star"></i>
                                                                                            </li>
                                                                                            <li className="list-inline-item">
                                                                                                <i className="las la-star"></i>
                                                                                            </li>
                                                                                            <li className="list-inline-item">
                                                                                                <i className="las la-star-half-alt"></i>
                                                                                            </li>
                                                                                            <li className="list-inline-item">(4.5)</li>
                                                                                        </ul>
                                                                                    </div>
                                                                                    <div className="reply-btn">
                                                                                        <button type="button"><i
                                                                                            className="las la-reply-all"></i> Reply
                                                                                        </button>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="comment-desc">
                                                                                    <p>Lorem ipsum dolor sit amet
                                                                                        consectetur adipisicing elit.
                                                                                        Architecto laborum quas placeat
                                                                                        perspiciatis est, nisi expedita
                                                                                        consectetur sit minus illum
                                                                                        laudantium nostrum dolore odit
                                                                                        asperiores quisquam ad enim
                                                                                        iusto laborum quas placeat
                                                                                        perspiciatis saepe.</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="comment-box d-flex">
                                                                            <div className="comment-image">
                                                                                <img
                                                                                    src={process.env.PUBLIC_URL + `/assets/images/testimonial-1.jpg`}
                                                                                    alt=""/>
                                                                            </div>
                                                                            <div className="comment-content">
                                                                                <div
                                                                                    className="content-title d-flex justify-content-between">
                                                                                    <div className="comment-writer">
                                                                                        <h6>Katrin Kay</h6>
                                                                                        <p>Mar 26, 2020 | 06:30pm</p>
                                                                                        <ul className="list-unstyled list-inline">
                                                                                            <li className="list-inline-item">
                                                                                                <i className="las la-star"></i>
                                                                                            </li>
                                                                                            <li className="list-inline-item">
                                                                                                <i className="las la-star"></i>
                                                                                            </li>
                                                                                            <li className="list-inline-item">
                                                                                                <i className="las la-star"></i>
                                                                                            </li>
                                                                                            <li className="list-inline-item">
                                                                                                <i className="las la-star"></i>
                                                                                            </li>
                                                                                            <li className="list-inline-item">
                                                                                                <i className="las la-star-half-alt"></i>
                                                                                            </li>
                                                                                            <li className="list-inline-item">(4.5)</li>
                                                                                        </ul>
                                                                                    </div>
                                                                                    <div className="reply-btn">
                                                                                        <button type="button"><i
                                                                                            className="las la-reply-all"></i> Reply
                                                                                        </button>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="comment-desc">
                                                                                    <p>Lorem ipsum dolor sit amet
                                                                                        consectetur adipisicing elit.
                                                                                        Architecto laborum quas placeat
                                                                                        perspiciatis est, nisi expedita
                                                                                        consectetur sit minus illum
                                                                                        laudantium nostrum dolore odit
                                                                                        asperiores quisquam ad enim
                                                                                        iusto laborum quas placeat
                                                                                        perspiciatis saepe.</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="comment-box d-flex">
                                                                            <div className="comment-image">
                                                                                <img
                                                                                    src={process.env.PUBLIC_URL + `/assets/images/testimonial-2.jpg`}
                                                                                    alt=""/>
                                                                            </div>
                                                                            <div className="comment-content">
                                                                                <div
                                                                                    className="content-title d-flex justify-content-between">
                                                                                    <div className="comment-writer">
                                                                                        <h6>David Show</h6>
                                                                                        <p>Mar 26, 2020 | 06:30pm</p>
                                                                                        <ul className="list-unstyled list-inline">
                                                                                            <li className="list-inline-item">
                                                                                                <i className="las la-star"></i>
                                                                                            </li>
                                                                                            <li className="list-inline-item">
                                                                                                <i className="las la-star"></i>
                                                                                            </li>
                                                                                            <li className="list-inline-item">
                                                                                                <i className="las la-star"></i>
                                                                                            </li>
                                                                                            <li className="list-inline-item">
                                                                                                <i className="las la-star"></i>
                                                                                            </li>
                                                                                            <li className="list-inline-item">
                                                                                                <i className="las la-star-half-alt"></i>
                                                                                            </li>
                                                                                            <li className="list-inline-item">(4.5)</li>
                                                                                        </ul>
                                                                                    </div>
                                                                                    <div className="reply-btn">
                                                                                        <button type="button"><i
                                                                                            className="las la-reply-all"></i> Reply
                                                                                        </button>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="comment-desc">
                                                                                    <p>Lorem ipsum dolor sit amet
                                                                                        consectetur adipisicing elit.
                                                                                        Architecto laborum quas placeat
                                                                                        perspiciatis est, nisi expedita
                                                                                        consectetur sit minus illum
                                                                                        laudantium nostrum dolore odit
                                                                                        asperiores quisquam ad enim
                                                                                        iusto laborum quas placeat
                                                                                        perspiciatis saepe.</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="review-form">
                                                                        <h5>Submit Review</h5>
                                                                        <ReviewForm/>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </Tab.Pane>)}

                                                        <Tab.Pane eventKey="videoprueba" className="curriculum-tab">

                                                            <div className="course-element">
                                                                <div>

                                                                    <div style={{paddingTop:"56.25%",position:"relative"}}>
                                                                        <iframe src={this.state.videoGratis}

                                                                                title="video"

                                                                                style={{
                                                                                    border:"0",
                                                                                    maxWidth:"100%",
                                                                                    position:"absolute"
                                                                                    ,top:"0",
                                                                                    left:"0",
                                                                                    height:"100%",
                                                                                    width:"100%",
                                                                                }}

                                                                                allowFullScreen="true"
                                                                                allow="encrypted-media"
                                                                        >

                                                                        </iframe>
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                </div>

                                                            </div>
                                                        </Tab.Pane>

                                                    </Tab.Content>
                                                </Tab.Container>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col lg="3" md="4" sm="12">
                                        <div className="single-details-sidbar">
                                            <Row>
                                                <Col md="12">
                                                    <div className="course-details-feature" style={{marginBottom: '50px'}}>
                                                        <h5 className="title">Course Details</h5>
                                                        <ul className="list-unstyled feature-list">
                                                            <li> Duración de videos: <span>{this.state.data.DuracionDeVideos}</span></li>
                                                            <li> Videos: <span>{this.state.data.Videos}</span></li>
                                                            <li> Quizes: <span>{this.state.data.Quizes}</span></li>
                                                            <li> Examenes Resueltos: <span>{this.state.data.ExamenesResueltos}</span></li>
                                                        </ul>

                                                      {!this.state.comprar && (<Popup price={this.state.data.price}
                                                              id={(handle + "-" + handle2)}/>)}

                                                    </div>
                                                </Col>
                                                <Row></Row>
                                                <Col md="12">
                                                    <CourseRecoms data={this.state.recos}/>
                                                </Col>
                                                        

                                            </Row>
                                        </div>

                                    </Col>
                                </Row>
                            )
                            }
                        </Container>
                    </section>
                </Styles>

                {/*Comments
                <BlogDetails arreComments = {this.state.comments} 
                             isComprado={this.state.comprar}
                             handles= {handle+'-'+handle2}/>*/}


                {/* Footer 2 */}
                <FooterTwo />


            </div >
        )
    }

}

export default CourseDetails

function Popup(props){

        const user = useContext(UserContext);
        const [show, setShow] = useState(false);
        const [aviso, setAviso] = useState(true)
        const [isLogedin, setIsLogedin] = useState(false);
        const [descuento, setDescuento] = useState(true)
        const [price, setPrice] = useState((props.price!==undefined) ? props.price : 1000)
        const [priceTxt, setPriceTxt] = useState("Precio: " )


    const [checked, setChecked] = useState(false);
        const handleChange = (event) => {
            setChecked(event.target.checked);
        };

    const [codigo, setCodigo] = useState("");
    const handleCodigo = (event) => {
        console.log(event.target.value)
        setCodigo(event.target.value);
    };

        const handleClose = () => {
            setShow(false)
            setAviso(true)
            setDescuento(true)
        }

    const handleAdd = () => {
            console.log("handleAdd")
            console.log("codigo",codigo)
            if(codigo === "ecoweek"){
                setPrice((props.price*.9))
                setPriceTxt("Codigo Valido Precio: "+( props.price*.9).toString()+" original: " + props.price.toString() )
            }
            else{
                setPrice(props.price)
                setPriceTxt("Precio: " + props.price.toString() )
            }
            setDescuento(false)
    }
    const handleNext = () => {
        setPrice(props.price)
        setPriceTxt("Precio: " + props.price.toString() )
        setDescuento(false)
    }


    const handleShow = () => setShow(true);
        const handleAviso = () => {
            setAviso(false)
            setPrice(props.price)
            setPriceTxt("Precio: " + props.price.toString() )
            console.log("props.price (handleShow)",props.price)
        }

        useEffect(()=>{

                try {
                    console.log("setPrice use efect")
                    setPrice(props.price)
                    setPriceTxt("Precio: " + props.price.toString() )
                }
                catch (e){
                    console.log(e)
                    setPrice(1000)
                    setPrice(undefined)
                    setPriceTxt("error " )

                }


            if(user != null){
                console.log("setIsLogedin: true")
                setIsLogedin(true)
            }
            else{
                console.log("setIsLogedin: false")
                //setIsLogedin(true)
                setIsLogedin(false)

            }
        },[user])

        return (
          <>
              <Button variant="primary" type="button" className="enroll-btn" onClick={handleShow}>
                  Comprar Curso
              </Button>

              <Modal show={show} onHide={handleClose}>
                  <Modal.Body closeButton>
                      {!isLogedin && (
                        <div style={{align:"center"}}>
                        <h2>Inicia sesión para continuar </h2>
                        </div>
                      )}
                      {isLogedin && aviso && (
                          <div>
                              <img src={process.env.PUBLIC_URL + "/assets/images/logo/a_logo.png"}
                                   style={{
                                       display: "block",
                                        marginLeft: "auto",
                                        marginRight: "auto",
                                        width: "20%",
                                        height: "auto",
                                   }}/>
                              <br/>
                              <h3>
                                  Recuerda que solo vas a poder acceder a tu suscripción desde este dispositivo y desde este navegador
                              </h3>
                              <br/>
                              <h4>
                                  Asegúrate de estar en el dispositivo y navegador que utilizaras mientras estudias con nuestro curso
                              </h4>
                              <br/>
                              <h4>
                                  ¡Gracias!
                              </h4>
                              <label style={{
                                  marginTop: "1.5rem"
                              }}>
                              <input
                              type="checkbox"
                              checked={checked}
                              onChange={handleChange}
                              style={{
                                  margin: "10px",
                              }}
                              />
                                 Entiendo
                              </label>
                          </div>
                      )}

                      {isLogedin && !aviso && descuento && (
                          <div>
                              <h2>Tienes algun codigo de descuento?</h2>
                              <label style={{
                                  marginTop: "1.5rem"
                              }}>
                                  <input
                                      type="text"
                                      checked={codigo}
                                      onChange={handleCodigo}
                                      style={{
                                          margin: "10px"
                                      }}
                                      placeholder="Codigo"
                                  />
                              </label>
                              <div>
                                  <Button variant="secondary" onClick={handleNext}>
                                      Omitir
                                  </Button>
                                  <Button variant="primary" onClick={handleAdd} style={{margin:"10px"}}>
                                      Agregar
                                  </Button>

                              </div>

                          </div>
                      )}


                      {isLogedin && !aviso && !descuento && (



                          <App  price={price} parcial={props.id} priceTxt={priceTxt} user={user} />


                      )}




                  </Modal.Body>


                  <Modal.Footer>
                      <Button variant="secondary" onClick={handleClose}>
                          Close
                      </Button>
                      {!isLogedin && (

                              <Link to="/login"><Button className="mb-2" style={{marginTop:"10%"}}>Log In </Button> </Link>

                      )}

                    {aviso && isLogedin && checked && <Button variant="primary" onClick={handleAviso}>
                      Continuar
                    </Button>}

                  </Modal.Footer>
              </Modal>
          </>
        );
    }

