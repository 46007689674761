import React  from 'react';


function Test(){

    return (
        <div>
            <div style={{paddingTop:"56.25%",position:"relative"}}>
                <iframe src="https://player.vdocipher.com/playerAssets/1.x/vdo/embed/index.html#otp=20160313versUSE323kYZgPgud0i0SB6s700C0WU04HW7TqP9V1GWEHxSzIf9sdW&playbackInfo=eyJ2aWRlb0lkIjoiOWE2MzIyYTkzYmI2NDE5Zjg2ZDBiMzM4OTFlMjkzMjEifQ=="
                        style={{border:"0",maxWidth:"100%",position:"absolute",top:"0",left:"0",height:"100%",width:"100%"}}
                        allowFullScreen="true"
                        allow="encrypted-media"
                        title="test">

                </iframe>

            </div>
        </div>
    )
}

export default Test