import React, {  useContext } from 'react';
import { Link } from 'react-router-dom';
import { UserContext } from "../../providers/UserProvider";
import {auth} from "../firebase/fbApp"

function User() {
    const user = useContext(UserContext);
    var uid = localStorage.getItem("Verification")

    if((user != null ) && (uid != null ) ){
        const {displayName} = user;
        return(
            <div className="bar-right d-flex justify-content-end">
                <ul className="list-unstyled list-inline bar-login">
                    <li className="list-inline-item"><Link to={process.env.PUBLIC_URL + "/my-account"}><i className="las la-user"></i>Hola, {displayName}</Link></li>
                    <li className="list-inline-item"><Link onClick = {() => {auth.signOut()}} to="/"> Sign out</Link></li>
                </ul>
            </div>
        )

    }
    else {
        return(
            <div className="bar-right d-flex justify-content-end">
                <ul className="list-unstyled list-inline bar-login">
                    <li className="list-inline-item"><Link to={process.env.PUBLIC_URL + "/login"}><i className="las la-user"></i>Log In</Link></li>
                    <li className="list-inline-item"><Link to={process.env.PUBLIC_URL + "/registration"}><i className="las la-user-edit"></i>Registrarse</Link></li>
                </ul>
            </div>
        )
    }

}


export default User