import React, { Component } from 'react';
import { Container, Row, Col, Tab, Nav } from 'react-bootstrap';
import { Styles } from "./styles/tabBox.js";

class TabBox extends Component {

    render() {
        return (
            <Styles>
                {/* Tab Box Area */}
                <section className="tab-section">
                    <Container>
                        <Tab.Container defaultActiveKey="why">
                            <Row>
                                <Col lg="3" md="4">
                                    <Nav className="flex-column">
                                        <Nav.Item>
                                            <Nav.Link eventKey="why"><i className="las la-arrow-right"></i>¿Por qué Ágora?</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="mission"><i className="las la-arrow-right"></i>Nuestra Misión</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="vision"><i className="las la-arrow-right"></i> Nuestra Visión</Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                </Col>
                                <Col lg="9" md="8">
                                    <Tab.Content>
                                        <Tab.Pane eventKey="why">
                                            <h4 className="tab-title">¿Por qué Ágora?</h4>
                                            <p className="tab-desc">Ágora nace de estudiantes para estudiantes que se toparon con la necesidad de entender sus materias. Lamentablemente los métodos de enseñanza no sirven para el 100% de los alumnos, ya que no todos comprenden de la misma manera. No es que no entiendas, simplemente no te han enseñado bien.
                                            </p>
                                            <ul className="list-unstyled check-list">
                                                <li><i className="fa fa-check"></i>En Ágora personalizamos cada clase, los 10 años de experiencia que tenemos nos permiten hacerlo. Entendemos que cada alumno entiende a su ritmo y a su manera
                                                </li>
                                                <li><i className="fa fa-check"></i>No son sólo videos, esto es e-learning
                                                </li>
                                                <li><i className="fa fa-check"></i>La práctica hace al maestro. Buscaremos practicar tantas veces sea necesario hasta que puedas hacer ese ejercicio con los ojos cerrados.
                                                </li>
                                            </ul>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="mission">
                                            <h4 className="tab-title">Nuestra Misión</h4>
                                            <p className="tab-desc">Ágora tiene una misión clara y simple:</p>
                                            <ul className="list-unstyled check-list">
                                                <li><i className="fa fa-check"></i>Que entiendas y que por ende pases. Pasa tu materia a la primera.</li>
                                            </ul>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="vision">
                                            <h4 className="tab-title">Nuestra Visión</h4>
                                            <p className="tab-desc">Ágora plus es líder en enseñanza y regularización de estudiantes, 10 años y mas de 10,000 estudiantes nos obliga a siempre estar mejorando y modernizando nuestros métodos.</p>
                                            <ul className="list-unstyled check-list">
                                                <li><i className="fa fa-check"></i>Semestre tras semestre se actualizan temarios y se mejoran los cursos impartidos por nuestros tutores</li>
                                                <li><i className="fa fa-check"></i>El 97% de aprobacion por parte de nuestros alumnos no es suficiente, queremos el 100%
                                                </li>
                                                <li><i className="fa fa-check"></i>Llegar a más materias, para poder apoyar a muchos más alumnos
                                                </li>
                                            </ul>
                                        </Tab.Pane>
                                    </Tab.Content>
                                </Col>
                            </Row>
                        </Tab.Container>
                    </Container>
                </section>
            </Styles>
        )
    }
}

export default TabBox
