import React, { useState, useEffect } from 'react';
import {firestore, storage} from "../../../components/firebase/fbApp";
import {Link} from 'react-router-dom';
import { Styles } from '../styles/course.js';


function RecomendedCourse(props) {
    const [url, setUrl] = useState("");

    const getUrl = async (url) => {
        return await storage.ref().child(url).getDownloadURL()
    }

    useEffect(() => {
        console.log(props.img)
        getUrl(props.img).then(resp => {
            setUrl(resp)
        })
        
    }, [])

    return(
    <li style={{alignItems: "center"}}>
        <Styles>
        <div style={{alignItems: "center"}}>
            <Link to={process.env.PUBLIC_URL +"/Parciales/"+ props.link}>
                <div>
                    <div className="course-details-banner">
                        <img src={url} alt="" className="img-fluid" />
                    </div>
                </div>
            </Link>
                <h5 style={{color:"gray", marginTop:"10px"}}><Link style={{color:"gray", marginTop:"10px"}}
                    to={process.env.PUBLIC_URL +"/Parciales/"+ props.link}>{props.nombre}</Link></h5>
        </div>
        </Styles>

    </li>
    )

}

export default RecomendedCourse