import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Styles } from '../styles/commentForm.js';
import {firestore, storage} from "../../../components/firebase/fbApp";

function CommentForm(props) {

    const desc = document.getElementById("comment_form-desc");
    const name = document.getElementById("comment_form-name");
    const email = document.getElementById("comment_form-email");

    function handleSubbmit(e){
       
        e.preventDefault();
        var uid = localStorage.getItem("Verification")
        //var uid ="x9kCmW5MrJb37BdhviDsAHxi9X02"
        //var uid = "0Ti9h6Aq9lVd5OYds6NVSdnkcft2"
        console.log(uid)
        const descValue = desc.value.trim();
        const nameValue = name.value.trim();
        const emailValue = email.value.trim();

        let mandable = true

        if (descValue === "") {
            setError(desc, "Comment can't be blank");
            mandable = false;
        } else {
            setSuccess(desc);
        }

        if (nameValue === "") {
            setError(name, "Name can't be blank");
            mandable = false;
        } else {
            setSuccess(name);
        }

        if (emailValue === "") {
            setError(email, "Email can't be blank");
            mandable = false;
        } else if (!isEmail(emailValue)) {
            setError(email, "Not a valid email");
            mandable = false;
        } else {
            setSuccess(email);
        }

        /*firestore.collection('users').where("email", "==", "lilianacont.04@gmail.com").get()
            .then(resp => {
                console.log(resp.docs[0].id)
            })*/

        if(mandable){
            let data = {}
            firestore.doc(`users/${uid}`)
                .get()
                .then(resp => {
                    if(resp.data().photoURL !== undefined){
                        console.log("NO")
                        data = {
                            approved: false,
                            date: new Date().getTime(),
                            img:  resp.data().photoURL,
                            name: nameValue,
                            text: descValue, 
                            email: emailValue, 
                            uid: uid,
                            idenReal: resp.data().displayName,
                            parcial: props.handles
                        }
                    }else{
                        console.log("NO")
                        data = {
                            approved: false,
                            date: new Date().getTime(),
                            img: 'https://graph.facebook.com/100348279110497/picture',
                            name: nameValue,
                            text: descValue, 
                            email: emailValue, 
                            uid: uid,
                            idenReal: resp.data().email,
                            parcial: props.handles
                        }
                    }
                    console.log(props.handles)
                    firestore.collection(`parciales/${props.handles}/comentarios`).add(data)
                    .then(() => {
                        document.getElementById("valor-retorno").innerHTML = "<h1>Recibimos tu comentario, </h1><h3> por seguridad aprobamos todos los comentarios antes de mostrarlos</h3>"
                    })
                })
        }else{
            console.log("NO")
        }
    }


    function setError(input, message) {
        const formControl = input.parentElement;
        const errorMsg = formControl.querySelector(".comment_form-input-msg");
        formControl.className = "form-control error";
        errorMsg.innerText = message;
    }

    function setSuccess(input) {
        const formControl = input.parentElement;
        formControl.className = "form-control success";
    }

    function isEmail(email) {
        return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(email);
    }

    return (
        <Styles>
            {/* Comment Form */}
            <div className="blog-comment-form">
                <h5>Deja un comentario</h5>
                <form id="comment_form" className="form" onSubmit={handleSubbmit}>
                    <Row>
                        <Col md="12">
                            <p className="form-control">
                                <textarea name="comment" id="comment_form-desc" placeholder="Enter your comment"></textarea>
                                <span className="comment_form-input-msg"></span>
                            </p>
                        </Col>
                        <Col md="6">
                            <p className="form-control">
                                <input type="name" placeholder="Enter your name" id="comment_form-name" />
                                <span className="comment_form-input-msg"></span>
                            </p>
                        </Col>
                        <Col md="6">
                            <p className="form-control">
                                <input type="email" placeholder="Enter your email" id="comment_form-email" />
                                <span className="comment_form-input-msg"></span>
                            </p>
                        </Col>
                        <Col md="12">
                            <button onClick={handleSubbmit}>Post Comment</button>
                            <div id="valor-retorno"></div>
                        </Col>
                        
                    </Row>
                </form>
            </div>
        </Styles>
    )
}

export default CommentForm