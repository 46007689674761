import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import BackToTop from './common/BackToTop';
import { Styles } from "./styles/footerTwo.js";

function FooterTwo() {
    useEffect(() => {
        const form = document.getElementById("form4");
        const email = document.getElementById("email4");

        form.addEventListener("submit", formSubmit);

        function formSubmit(e) {
            e.preventDefault();

            const emailValue = email.value.trim();

            if (emailValue === "") {
                setError(email, "Email can't be blank");
            } else if (!isEmail(emailValue)) {
                setError(email, "Not a valid email");
            } else {
                setSuccess(email);
            }
        }

        function setError(input, message) {
            const formControl = input.parentElement;
            const errorMsg = formControl.querySelector(".input-msg4");
            formControl.className = "form-control error";
            errorMsg.innerText = message;
        }

        function setSuccess(input) {
            const formControl = input.parentElement;
            formControl.className = "form-control success";
        }

        function isEmail(email) {
            return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(email);
        }
    });

    return (
        <Styles>
            {/* Footer Two */}
            <footer className="footer2" >
                <Container>
                    <Row>
                        <Col md="4">
                            <div className="footer-logo-info">
                                <img src={process.env.PUBLIC_URL + "/assets/images/logo/a_logo_white.png"} alt="" className="img-fluid" />
                                <p>Ágora nace de estudiantes para estudiantes que se toparon con la necesidad de
                                    entender sus materias, lamentablemente los métodos de enseñanza no sirven para el
                                    100% de los alumnos, ya que no todos comprenden de la misma manera, por ello desarrollamos
                                    metodos personalizados para que cada quien aprenda a su ritmo </p>                                <ul className="list-unstyled">
                                    <li><i className="las la-map-marker"></i>Puebla #18, col. Progreso Tizapán, CDMX</li>
                                    <li><i className="las la-envelope"></i>hola@agoraplus.mx</li>
                                </ul>
                            </div>
                        </Col>
                        <Col md="2 offset-1">
                            <div className="f-links">
                                <h5>Links Utiles</h5>
                                <ul className="list-unstyled">
                                    <li><Link to={process.env.PUBLIC_URL + "/"}><i className="las la-angle-right"></i>Inicio</Link></li>
                                    <li><Link to={process.env.PUBLIC_URL + "/about"}><i className="las la-angle-right"></i>Nosotros</Link></li>
                                    <li><Link to={process.env.PUBLIC_URL + "/course-grid"}><i className="las la-angle-right"></i>Cursos</Link></li>
                                    <li><Link to={process.env.PUBLIC_URL + "/contact"}><i className="las la-angle-right"></i>Dar una clase</Link></li>
                                    <li><Link to={process.env.PUBLIC_URL + "/politica-de-privacidad"}><i className="las la-angle-right"></i>Privacy Policy</Link></li>
                                    <li><Link to={process.env.PUBLIC_URL + "/terminos-y-condiciones"}><i className="las la-angle-right"></i>Términos y Condiciones</Link></li>
                                </ul>
                            </div>
                        </Col>

                        <Col md="3 offset-2">
                            <div className="f-newsletter">
                                <h5>Newsletter</h5>
                                <p>Mantente enterado de nuevos cursos</p>

                                <form id="form4" className="form">
                                    <p className="form-control">
                                        <input type="email" placeholder="Enter email here" id="email4" />
                                        <span className="input-msg4"></span>
                                    </p>
                                    <button>Submit</button>
                                </form>
                            </div>
                        </Col>
                        <Col md="12">
                            <div className="copytext-area text-center">
                                <p>Copyright &copy; {(new Date().getFullYear())} | Built  by <a href="https://pointech.dev/" target="_blank" rel="noopener noreferrer">Point.</a></p>

                            </div>
                        </Col>
                    </Row>
                </Container>

                {/* Back To Top  */}
                <BackToTop />
            </footer>
        </Styles>
    );
}

export default FooterTwo
