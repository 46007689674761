import React, { Component } from 'react';
import App from "./app";


class StripeCheckout extends Component {

    render() {
        return (
            <App  price={10} parcial={"Economia-1-parcial-3"}/>
        )
    }
}

export default StripeCheckout