import React, { useState, useEffect } from 'react';
import {firestore, storage} from "../../components/firebase/fbApp";
import {Link} from 'react-router-dom';
import RecomendedCourse from "./components/RecomendedCourse";


function Recom(props) {
    const [url, setUrl] = useState("");

    const getUrl = async (url) => {
        return await storage.ref().child(url).getDownloadURL()
    }

    useEffect(() => {
        console.log(props.img)
        getUrl(props.img).then(resp => {
            setUrl(resp)
        })
        
    }, [])

    return(
    <li>
         <div className="course-item">
            <div className="course-content">
                <h6 className="heading"><Link
                    to={process.env.PUBLIC_URL +"/Parciales/"+ props.link}>{props.nombre}</Link></h6>
            </div>
        </div>
    </li>
    )

}


function CourseRecoms(props) {

    const [run, setRun] = useState(true)
    const [recos, setRecos] = useState([]);
    const [countRecos, setCountRecos] = useState(0);
    //const [comprado, setComprado] = useState(false);

    const getRec = async (id) => {
        return await firestore.doc(`cursos/${id}`).get()
    }
    
    
    if(props.data != undefined && props.data.length > 0 && run){
        setRun(false);
        setCountRecos(props.data.length)
        let arreAux = [];
        console.log(props.data[0].data().idRec)
        props.data.forEach(recom => {
            arreAux.push(recom.data())
        });
        console.log(arreAux)

        setRecos(arreAux)
    }

    let rendRecos = recos ? (
        recos.map(rec => 
        (<RecomendedCourse nombre={rec.nombre} img={rec.img} link={rec.link}/>)
        )
    
        ) : (<></>);


    return (
        <div className="course-details-feature" style={{marginBottom: '50px', alignItems: "center"}}>
            <h5 className="title">Recomendations</h5>
            <ul className="list-unstyled feature-list">
                {rendRecos}
            </ul>
        </div>
    )
}

export default CourseRecoms
