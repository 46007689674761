import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import StickyMenu from './common/StickyMenu';
import MobileMenu from './common/MobileMenu';
import { Styles } from "./styles/header.js";
import User from "./common/User";


class Header extends Component {
    render() {
        return (
            <Styles>
                {/* Topbar */}
                <section className="top-bar">
                    <Container>
                        <Row>
                            <Col lg="6" md="5">
                                <div className="bar-left">
                                    <ul className="list-unstyled list-inline">
                                        <li className="list-inline-item"><i className="las la-map-marker"></i>Puebla #18, col.  Progreso Tizapán, CDMX</li>
                                        <li className="list-inline-item"><Link to={process.env.PUBLIC_URL + "/faq"}>FAQ</Link></li>
                                    </ul>
                                </div>
                            </Col>
                            <Col lg="6" md="7">
                                <User />
                            </Col>
                        </Row>
                    </Container>
                </section>

                {/* Logo Area */}
                <section className="logo-area">
                    <Container>
                        <Row>
                            <Col md="3">
                                <div className="logo">
                                    <Link to={process.env.PUBLIC_URL + "/"}><img src={process.env.PUBLIC_URL + "/assets/images/logo/agora_beta.png"} alt="" /></Link>
                                </div>
                            </Col>
                            <Col md="9">
                                <div className="logo-contact-box d-flex justify-content-end">
                                    <div className="emcontact-box d-flex">
                                        <div className="box-icon">
                                            <i className="flaticon-phone-call"></i>
                                        </div>
                                        <div className="box-content">
                                            <p>WhatsApp</p>
                                            <span>5578389757</span>
                                        </div>
                                    </div>
                                    <div className="emcontact-box d-flex">
                                        <div className="box-icon">
                                            <i className="flaticon-envelope"></i>
                                        </div>
                                        <div className="box-content">
                                            <p>Contactanos</p>
                                            <span>hola@agoraplus.mx</span>
                                        </div>
                                    </div>

                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>

                {/* Navbar */}
                <section className="main-menu">
                    <Container>
                        <Row>
                            <Col md="12">
                                <div className="main-menu-box">
                                    <div className="menu-box d-flex justify-content-between">
                                        <ul className="nav menu-nav">
                                            <li className="nav-item  active">
                                                <Link className="nav-link " to={process.env.PUBLIC_URL + "/"} >Inicio <i className="las"></i></Link>
                                            </li>

                                            <li className="nav-item ">
                                                <Link className="nav-link " to={process.env.PUBLIC_URL + "/course-grid"} >Cursos <i className="las"></i></Link>

                                            </li>
                                            <li className="nav-item ">
                                                <Link className="nav-link " to={process.env.PUBLIC_URL + "/about"} >Nosotros <i className="las "></i></Link>
                                            </li>
                                            <li className="nav-item ">
                                                <Link className="nav-link " to={process.env.PUBLIC_URL + "/contact"} >Dar una clase <i className="las "></i></Link>
                                            </li>
                                        </ul>

                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>

                {/* Sticky Menu */}
                <StickyMenu />

                {/* Mobile Menu */}
                <MobileMenu />
            </Styles>
        )
    }
}

export default Header
