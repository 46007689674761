import React, {Component } from 'react';
import { Container, Row, Col, } from 'react-bootstrap';
import HeaderTwo from '../../components/HeaderTwo';
import { BreadcrumbBox } from '../../components/common/Breadcrumb';

import FooterTwo from '../../components/FooterTwo';
import { Styles } from '../shop/styles/product';
import {firestore, storage} from "../../components/firebase/fbApp";

//import BlogDetails from "../../pages/blog/BlogDetails";
import BlogSidebar from "../../pages/blog/components/BlogSidebar";

import {Link} from "react-router-dom";

import ReactLoading from "react-loading";
import BlogDetails from '../blog/BlogDetails';


const getUrl = async (img) => {
    await img
    try {
        const url =   storage.ref().child(img).getDownloadURL()
        return await url
    } catch (error) {
        console.log(error)
        return (process.env.PUBLIC_URL + '/assets/images/about-1.jpg')
    }

}

const getParciales = async (handle) => {

    try {
        const data = await firestore.collection('cursos').where('courseLink', '==', handle).get()

        let parciales = []

        for(let item in data.docs[0].data()["Parciales"]){
            await getUrl(data.docs[0].data()["Parciales"][item].imgURL)
                .then((url)=>{
                        const tmp = {
                            id: data.docs[0].data()["Parciales"][item].id,
                            productImg:url,
                            productTitle: data.docs[0].data()["Parciales"][item].Titulo,
                            productUrl: data.docs[0].data()["Parciales"][item].productUrl,
                            price: data.docs[0].data()["Parciales"][item].price,

                        }
                    parciales.push(tmp)
                    }
                )
        }
        return parciales

    } catch (error) {
        console.error("Error fetching parciales", error);
    }
}

class Parciales extends Component{
    constructor(props) {
        super(props);
        this.state={
            loading: true,
            datas:[],
            handle: "",
        }
    }

    componentDidMount(){
        let {handle}=this.props.match.params
        getParciales(handle).then((res)=> {
                this.setState({
                    loading: false,
                    datas: res,
                    handle:handle,
                })
            }
        )
        const courseButton = document.querySelectorAll(".course-button");
        courseButton.forEach(button => {
            button.addEventListener("click", () => {
                button.classList.toggle("active");
                const content = button.nextElementSibling;

                if (button.classList.contains("active")) {
                    content.className = "course-content show";
                    content.style.maxHeight = content.scrollHeight + "px";
                } else {
                    content.className = "course-content";
                    content.style.maxHeight = "0";
                }
            });
        });

    }

render(){
    return (
        <Styles>
            {/* Main Wrapper */}
            <div className="main-wrapper product-page">

                {/* Header 2 */}
                <HeaderTwo />

                {/* Breadcroumb */}
                <BreadcrumbBox title={this.state.handle} />

                {/* Products */}
                <section className="product-area">
                    <Container>
                        <Row>
                            <Col lg="11" md="8" sm="7">
                                <Row>
                                    {this.state.loading &&
                                        <ReactLoading type="bubbles" color="#11B67A" height={'10%'} width={'10%'} />
                                    }
                                    {!this.state.loading &&
                                    this.state.datas.map((data, i) => (
                                        <Col lg="4" md="6" key={i}>
                                            <div className="product-box">
                                                <div className="product-img">
                                                    <img src={data.productImg} alt="" className="img-fluid" />
                                                    <div className="layer-box"></div>
                                                    <Link className="item_view" to={process.env.PUBLIC_URL + "/course-details/"+this.state.handle+"/" + data.productUrl}>Ver Detalles</Link>
                                                </div>
                                                <div className="product-content text-center">
                                                    <div className="pro-title">
                                                        <h5><Link to={process.env.PUBLIC_URL + "/course-details/"+this.state.handle+"/" + data.productUrl}>{data.productTitle}</Link></h5>
                                                    </div>
                                                    <div className="pro-price">
                                                        <p>${data.price}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    ))
                                    }
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </section>

                {/*------------------------------------------------------*/}
                

                {/* Footer 2 */}
                <FooterTwo />

            </div>
        </Styles>
    )

}

}

export default Parciales