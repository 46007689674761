import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import CourseCategory from './CourseCategory';

class CourseSidebar extends Component {
    render() {
        return (
            <div className="course-sidebar">
                <Row>

                    <Col md="12">
                        <CourseCategory changeFilter={this.props.changeFilter} deactivateFilter={this.props.deactivateFilter}/>
                    </Col>
                </Row>
            </div>
        )
    }
}

export default CourseSidebar
