import React, { useState} from 'react';
import {Link} from 'react-router-dom';
import {Container, Row, Col, Button, Modal} from 'react-bootstrap';
import HeaderTwo from '../../components/HeaderTwo';
import {BreadcrumbBox} from '../../components/common/Breadcrumb';
import FooterTwo from '../../components/FooterTwo';
import {Styles} from './styles/account.js';
import {generateUserDocument} from "../../components/firebase/fbApp";
import firebase from "firebase";


function Popup2() {
  const [show, setShow] = useState(true);
  const [aviso, setAviso] = useState(true)
  const handleClose = () => {
    setShow(false)
    setAviso(true)
  }


  return (
    <>

      <Modal show={show} onHide={handleClose}>

        <Modal.Header closeButton>
          <Modal.Title>¡AVISO!</Modal.Title>
        </Modal.Header>


        <Modal.Body>

          {aviso && (
              <div>
                <img src={process.env.PUBLIC_URL + "/assets/images/logo/a_logo.png"}
                     style={{
                       display: "block",
                       marginLeft: "auto",
                       marginRight: "auto",
                       width: "20%",
                       height: "auto",
                     }}/>
                <br/>
                <h3>
                  Recuerda que solo vas a poder acceder a tu usario desde este dispositivo y desde este navegador
                </h3>
                <br/>
                <h4>
                  Asegúrate de estar en el dispositivo y navegador que utilizaras mientras estudias con nuestro curso
                </h4>
                <br/>
                <h4>
                  ¡Gracias!
                </h4>
              </div>
          )}


        </Modal.Body>


        <Modal.Footer>

          <Button variant="primary" onClick={handleClose}>
            Entendido
          </Button>

        </Modal.Footer>
      </Modal>
    </>
  );
}

function Register() {

  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [success, setSuccess] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");



  const setVerifyDevice = (id) => {
    localStorage.setItem("Verification", id);
    console.log("set", id)
  }


  const signUpWithFacebook =  () => {
    var provider = new firebase.auth.FacebookAuthProvider();
    firebase
      .auth()
      .signInWithPopup(provider)
      .then((result) => {
        /** @type {firebase.auth.OAuthCredential} */
        var credential = result.credential;

        // The signed-in user info.
        var user = result.user;

        // This gives you a Facebook Access Token. You can use it to access the Facebook API.
        var accessToken = credential.accessToken;
        console.log(user, accessToken,user.uid)
        const date = Date.now();
        generateUserDocument(user, user.uid,date).then((userCreated)=>{
          try{
            console.log("test new user",user.uid,userCreated,userCreated.dateCreated,date)
            if(userCreated.dateCreated===date){
              console.log("yes verify")
              setVerifyDevice(user.uid)
              window.location = "/"
            }
          }
          catch (e){
            console.log("not set verify",e)
          }
        }
        )

        setSuccess(true)
        setSuccessMsg("Registrado Correctamente")

        //redirect

        // ...
      })
      .catch((error) => {
        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;
        // The email of the user's account used.
        var email = error.email;
        // The firebase.auth.AuthCredential type that was used.
        var credential = error.credential;
        console.log(errorCode, errorMessage, email, credential)
        setError(true)
        setErrorMsg(errorMessage)
        // ...
      });


  }


  return (
    <Styles>
      {/* Main Wrapper */}
      <div className="main-wrapper registration-page">

        {/* Header 2 */}
        <HeaderTwo/>

        {/* Breadcroumb */}
        <BreadcrumbBox title="Registration"/>

        {/* Registration Area */}
        <section className="registration-area">
          <Container>
            <Row>
              <Col md="12">
                <div className="registration-box">
                  <div className="registration-title text-center">
                    <h3>Registration</h3>
                  </div>

                  <div className="social-login text-center">
                    <Popup2/>
                    <ul className="list-unstyled list-inline">
                      <li onClick={signUpWithFacebook} className="list-inline-item facebookButton"><i
                        className="fab fa-facebook-f"></i>  Facebook
                      </li>
                    </ul>
                  </div>
                  <div className="have_account-btn text-center">
                    <p>Dont have facebook? <Link to="/registrationmail">Request an account</Link></p>
                  </div>

                  {error && (<p style={{color: "red"}}>{errorMsg}</p>)}
                  {success && (<p style={{color: "green"}}>{successMsg}</p>)}
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        {/* Footer 2 */}
        <FooterTwo/>

      </div>
    </Styles>
  )
}

export default Register

