import React from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./chekoutForm";
import {Styles} from "./appCss";
import OxxoPayForm from "./OxxoPayForm";

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// loadStripe is initialized with your real test publishable API key.

const promise = loadStripe("pk_live_51JFQQDFnhbaCFn45I2nom0Pgth59zZdJr4sS4WO3IimQUrDZlHXZIN4zHxxjUuVilXRrncQ3UNjQYGXXVF8jl7Pn008whzdQOH");
//const promise = loadStripe("pk_test_51JFQQDFnhbaCFn451hh0R5r0XRfmTiXVi9bsmlHVpSfJxYxrCzBTMuS0kizpRTawDVtbBCb7UTsXAWqlN9WwxC7t00N3vUxjdA");


export default function App(props) {
    return (
      <Styles>
        <div className="App">

            <Elements stripe={promise}>
                <h4>{props.priceTxt}</h4>
                <CheckoutForm price={props.price} parcial={props.parcial}  />
                <OxxoPayForm price = {props.price} parcial = {props.parcial} user={props.user}/>
                <img className="imgPayment" src={process.env.PUBLIC_URL + `/assets/images/stripetrust.png`} alt={""}/>
            </Elements>
        </div>
      </Styles>
    );
}
