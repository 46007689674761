import React, { useState, useEffect } from "react";
import {
    CardElement,
    useStripe,
    useElements
} from "@stripe/react-stripe-js";
import {firestore} from "../components/firebase/fbApp";

export default function CheckoutForm(props) {
    const [succeeded, setSucceeded] = useState(false);
    const [error, setError] = useState(null);
    const [processing, setProcessing] = useState('');
    const [disabled, setDisabled] = useState(true);
    const [clientSecret, setClientSecret] = useState('');
    
    const [nombre, setNombre] = useState("")
    const [pais, setPais] = useState("")
    const [estado, setEstado] = useState("")
    const [ciudad, setCiudad] = useState("")
    const [calle, setCalle] = useState("")
    //const [cp, setCp] = useState("")
    //const [interior, setInterior] = useState(0)

    const [auxDis, setAuxDis] = useState(false);
    const [auxMsj, setAuxMsj] = useState(false);

    const stripe = useStripe();
    const elements = useElements();

    useEffect(() => {
        // Create PaymentIntent as soon as the page loads
        console.log("useEffect start")
        const price =  (props.price*100).toString()
        fetch("https://us-central1-agora-plus.cloudfunctions.net/createPaymentIntent", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Origin": "http://localhost:3000",
                    "Access-Control-Request-Method": "POST",
                    "Access-Control-Request-Headers": "Content-Type"

                },
                body: JSON.stringify({price: price})
            })
            .then(res => {
                console.log("then 1")
                return res.json();
            })
            .then(data => {
                console.log("clientSecret",data.clientSecret)
                setClientSecret(data.clientSecret);
            }).catch((e)=>{
                console.log("error",e)
        }

        );
        console.log("useEffect finish")
    }, [props.price]);

    const setUserPermisions = async (parcial) => {
       var uid = localStorage.getItem("Verification")
        //var uid ="x9kCmW5MrJb37BdhviDsAHxi9X02"
        const userRef = firestore.doc(`users/${uid}`);
        const snapshot = await userRef.get();
        console.log(snapshot)
        if (snapshot.exists) {
            const date = Date.now();
            var key = parcial
            var data = {}
            data[key] = date
             try {
                await userRef.set(
                  {
                      comprado: {
                          data:data
                      }

                  }, { merge: true }

                ).then(()=>
                  {
                      console.log("yei")
                      return  true
                  })
            } catch (error) {
                console.error("Error creating user document", error);
                throw error
            }

        }
    }

    const cardStyle = {
        style: {
            base: {
                color: "#32325d",
                fontFamily: 'Arial, sans-serif',
                fontSmoothing: "antialiased",
                fontSize: "16px",
                "::placeholder": {
                    color: "#32325d"
                }
            },
            invalid: {
                color: "#fa755a",
                iconColor: "#fa755a"
            }
        }
    };

    const handleChangePais = (e) => {
        e.preventDefault()
        setPais(e.currentTarget.value)
        if(e.currentTarget.value === 'Otro'){
            setAuxDis(true)
            setAuxMsj("No es necesaria más información, proceda al pago")
        }else{
            setAuxDis(false)
            setAuxMsj("")
        }
    }

    const handleChange = async (event) => {
        // Listen for changes in the CardElement
        // and display any errors as the customer types their card details
        setDisabled(event.empty);
        setError(event.error ? event.error.message : "");
        //console.log(elements.getElement(CardElement))
    };

    const handleSubmit = async ev => {
        ev.preventDefault();
        setProcessing(true);
        if(nombre !== "" && pais !== "Otro" && pais !== "País:" && estado !== "Estado:" && ciudad !== "" && calle !== ""){  
            
            const payload = await stripe.confirmCardPayment(clientSecret, {
                payment_method: {
                    card: elements.getElement(CardElement),
                    billing_details: {
                        address: {
                            city: ciudad,
                            country: "MX",
                            line1: calle,
                            state: estado
                        },
                        name: nombre,
                      },
                }
            });
            //console.log("payload",payload.paymentIntent.id)

            if (payload.error) {
                setError(`Payment failed ${payload.error.message}`);
                setProcessing(false);
            }else{
                setError(null);
                setProcessing(false);
                setSucceeded(true);
                setUserPermisions(props.parcial).then( ()=>{
                    console.log("permisions set")
                    setProcessing(false);
                    setSucceeded(true);
                }).catch(()=>{
                    setError(`Setting up permissions failed please contact support with this ID:`+ payload.paymentIntent.id);
                })
            }
        }else if(pais === "Otro"){
            const payload = await stripe.confirmCardPayment(clientSecret, {
                payment_method: {
                    card: elements.getElement(CardElement),
                    billing_details: {
                        name: nombre
                      }
                }
            });
            //console.log("payload",payload.paymentIntent.id)

            if (payload.error) {
                setError(`Payment failed ${payload.error.message}`);
                setProcessing(false);
            } else {
                setError(null);
                setProcessing(false);
                setSucceeded(true);
                setUserPermisions(props.parcial).then( ()=>{
                    console.log("permisions set")
                    setProcessing(false);
                    setSucceeded(true);
                }).catch(()=>{
                    setError(`Setting up permissions failed please contact support with this ID:`+ payload.paymentIntent.id);
                })
            }
        }
        else{
            setError(`Faltan datos`);
            setProcessing(false);
        }
    };

    return (
        <form id="payment-form" style={{width:"100%"}} onSubmit={handleSubmit}>
            <h3>Pago con tarjeta</h3>
            <br/>
            Si eres cliente de BBVA recomendamos usar tu tarjeta digital. 
            <CardElement id="card-element" options={cardStyle} onChange={handleChange} />
            <input type= "text" placeholder="Nombre del titular"  onChange={e => {setNombre(e.currentTarget.value)}}></input>
            <h5>Dirección de facturación</h5>
            <select placeholder="País" onChange={handleChangePais}>
                <option>País:</option>
                <option>México</option>
                <option>Otro</option>
            </select>
            {auxMsj}
            <select placeholder="País" disabled={auxDis} onChange={e => {setEstado(e.currentTarget.value)}}>
                <option>Estado:</option>
                <option>Aguascalientes</option>
                <option>Baja California</option>
                <option>Baja California Sur</option>
                <option>Campeche</option>
                <option>CDMX</option>
                <option>Chiapas</option>
                <option>Chihuahua</option>
                <option>Coahuila</option>
                <option>Colima</option>
                <option>Durango</option>
                <option>Guanajuato</option>
                <option>Guerrero</option>
                <option>Hidalgo</option>
                <option>Jalisco</option>
                <option>Estado de México</option>
                <option>Michoacán</option>
                <option>Morelos</option>
                <option>Nayarit</option>
                <option>Nuevo León</option>
                <option>Oaxaca</option>
                <option>Puebla</option>
                <option>Querétaro</option>
                <option>Quintana Roo</option>
                <option>San Luis Potosí</option>
                <option>Sinaloa</option>
                <option>Sonora</option>
                <option>Tabasco</option>
                <option>Tamaulipas</option>
                <option>Tlaxcala</option>
                <option>Veracrúz</option>
                <option>Yucatán</option>
                <option>Zacatecas</option>
            </select>
            <input type= "text" placeholder="Ciudad" disabled={auxDis} onChange={e => {setCiudad(e.currentTarget.value)}}></input>
            <input type= "text" placeholder="Calle y número" disabled={auxDis} onChange={e => {setCalle(e.currentTarget.value)}}></input>
            {/*<input type= "text" placeholder="Código Postal" disabled={auxDis} onChange={e => {setCp(e.currentTarget.value)}}></input>
            <input type= "text" placeholder="Número interior" disabled={auxDis} onChange={e => {setInterior(e.currentTarget.value)}}></input>*/}

            <button
                disabled={processing || disabled || succeeded}
                id="submit"
            >
        <span id="button-text">
          {processing ? (
              <div className="spinner" id="spinner"></div>
          ) : (
              "Pay now"
          )}
        </span>
            </button>
            {/* Show any error that happens when processing the payment */}
            {error && (
                <div className="card-error" role="alert">
                    {error}
                </div>
            )}
            {/* Show a success message upon completion */}
            <p className={succeeded ? "result-message" : "result-message hidden"}>
                ¡Pago exitoso! Continúa a
                <a
                    href={`https://agoraplus.mx/my-account`}
                >
                    {" "}
                    tu perfil
                </a> o refezca la página para ver el contenido.
            </p>
        </form>
    );
}