import React, {Component} from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import HeaderTwo from '../../components/HeaderTwo';
import {BreadcrumbBox} from '../../components/common/Breadcrumb';
import FooterTwo from '../../components/FooterTwo';
import {Styles} from '../../pages/instructor/styles/instructor';
import {firestore} from "../../components/firebase/fbApp";
import ReactLoading from "react-loading";
import {Link} from "react-router-dom";


const userData = async () => {
  var uid = localStorage.getItem("Verification")
  //var uid = "x9kCmW5MrJb37BdhviDsAHxi9X02"
  console.log(uid)
  if (uid != null) {
    const userRef = firestore.collection('users').doc(uid);
    const snapshot = await userRef.get();
    console.log("snapshot.data()",snapshot.data())
    return snapshot.data()
  }
}

class Profile extends Component {

  constructor() {
    super();
    this.state = {
      email: "",
      name: "",
      photoURL: "",
      cursos: [],

      logIn: false,
      loading: true,
    }
  }

  componentDidMount() {
    userData().then((data) => {
      try {
        this.setState({
          email: data.email,
          name: data.displayName,
          photoURL: data.photoURL,
          cursos: data.comprado.data,
          logIn: true,
          loading: false,
        })
      } catch (e) {
        this.setState({
          email: data.email,
          name: data.displayName,
          photoURL: data.photoURL,
          logIn: true,
          loading: false,
        })
      }


    }).catch((e) => {
      console.log("error in fech user data", e)
      this.setState({
        logIn: false,
        loading: false,
      })
    })
  }


  render() {


    return (
      <Styles>
        {/* Main Wrapper */}
        <div className="main-wrapper instructor-details-page">

          {/* Header 2 */}
          <HeaderTwo/>

          {/* Breadcroumb */}
          <BreadcrumbBox title="Tu Perfil"/>

          {/* Instructor Details Area */}

          {this.state.loading &&
          <ReactLoading type="bubbles" color="#11B67A" height={'10%'} width={'10%'}/>
          }

          {!this.state.loading && !this.state.logIn &&
          (<div>
            <h1>Parece ser que no estas Log In</h1>
            <p> Si el problema continua contactanos.</p>
          </div>)
          }

          {!this.state.loading && this.state.logIn && (
            <section>
              <Container style={{padding: "10px"}}>
                <Row>
                  <Col md="1">
                    <div className="instructor-img">

                      {/*FOTO DEL USUARIO*/}
                      <img src={this.state.photoURL} alt="" className="img-fluid"/>


                    </div>
                  </Col>
                  <Col md="8">

                    {/*NOMBRE DEL USUARIO*/}
                    <div className="instructor-content2">
                      <h4>{this.state.name}</h4>
                      <span>{this.state.email}</span>

                    </div>


                    <div className="curriculum-tab">
                      <div className="course-details-area2">
                        <div className="course-element2">
                          <br/>
                          {Object.keys(this.state.cursos).map((key) => {
                              console.log("cursos", key, this.state.cursos[key])
                              const date = new Date(this.state.cursos[key])
                              return (
                                <>
                                  <h5>Cursos Comprados</h5>
                                  <div className="course-item2">
                                    <div className="course-content2 show2">
                                      <ul className="list-unstyled2 ">
                                        <div key={key}>
                                          <Link className="color-black" to={"/course-details/" + key.replace("-p", "/p")}
                                                key={key}>
                                            <li key={key} className="list-item">
                                            <span className="play-icon">
                                              Curso: {key.replace(/-/g, " ")}
                                            </span>
                                              <span className="lecture-title2">
                                               Comprado: {date.toDateString()}
                                            </span>
                                            </li>
                                          </Link>
                                        </div>
                                      </ul>
                                    </div>
                                  </div>
                                </>
                              )
                            }
                          )}

                        </div>
                      </div>
                    </div>


                  </Col>

                </Row>

              </Container>
            </section>
          )}


          {/* Footer 2 */}
          <FooterTwo/>

        </div>
      </Styles>
    )
  }
}

export default Profile