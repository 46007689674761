import React, {Component, Fragment} from 'react';
import {Link} from 'react-router-dom';
import {Col} from 'react-bootstrap';
import {firestore, storage} from "../../../components/firebase/fbApp";
import ReactLoading from 'react-loading';


const getUrl = async (img) => {
    await img
    try {
        const url =   storage.ref().child(img).getDownloadURL()
        return await url
    } catch (error) {
        console.log(error)
        return (process.env.PUBLIC_URL + '/assets/images/about-1.jpg')
    }

}

const getCourses = async () => {
    try {
        const data = await firestore.collection('cursos').get();
        console.log("data.docs[0].data()",data.docs[0].data())

        let cursos = []


        for(let item in data.docs){
            console.log("item",data.docs[item].data()["imgUrl"])
            await getUrl(data.docs[item].data().imgUrl)
                .then((url)=>{
                    const tmp = {
                        authorCourses: data.docs[item].data().authorCourses,
                        authorName: data.docs[item].data().authorName,
                        category: data.docs[item].data().category,
                        courseDesc:  data.docs[item].data().courseDesc,
                        courseTitle: data.docs[item].data().courseTitle,
                        imgUrl: url,
                        price: data.docs[item].data().price,
                        stars: data.docs[item].data().stars,
                        studentCount: data.docs[item].data().studentCount,
                        time: data.docs[item].data().time,
                        universidad: data.docs[item].data().universidad,
                        courseLink: data.docs[item].data().courseLink,
                    }
                    console.log("tmp", tmp)
                cursos.push(tmp)
                    return tmp
                }

            )
        }
        console.log("cursos",cursos)
        return cursos


    } catch (error) {
        console.error("Error fetching cursos", error);
    }
}

const filter = (filter,data) => {
    return data.filter( (item) => {
        console.log((item.category === filter))
        return (item.category === filter)
    } )
}

class CourseItemGrid extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            dataRaw: [],
            loading: true,
            imgCursos: [],
            filter:this.props.filter,
            active: this.props.active,

        }

    }

    componentDidMount() {
        getCourses().then((res)=>{
            this.setState({
                data: res,
                dataRaw: res,
                loading: false,
            })
        })
    }

    stars(num) {
        let stars = parseInt(num)
        let itemList = [];
        for (let i = 0; i < stars; i++) {
            itemList.push(<li className="list-inline-item" key={i}><i className="las la-star"></i></li>)
        }
        if (num - stars > 0) {
            itemList.push(<li className="list-inline-item" key={num + (new Date().getTime())}><i
                className="las la-star-half-alt"></i></li>)
        }
        let rest = parseInt((5 - num))
        for (let i = 0; i < rest; i++) {
            itemList.push(<li className="list-inline-item" key={stars + (new Date().getTime()) + i}><i
                className="las la-star" style={{color: "#B8B8B8"}}></i></li>)
        }
        return (itemList)
    }

     render() {
         let data = {}
         console.log("this.props.active",this.props.active)
        if(this.props.active){
            console.log("this.props.filter",this.props.filter)
             data = filter(this.props.filter,this.state.dataRaw)
        }
        else{
             data = this.state.dataRaw
        }
        return (
            <Fragment>
                {/* Course Item */}
                {this.state.loading &&
                        <ReactLoading type="bubbles" color="#11B67A" height={'10%'} width={'10%'} />
                }
                {!this.state.loading &&
                data.map((info, i) => {
                        const data = info
                        return (
                            <Col lg="6" md="12" key={i}>
                                <div className="course-item">
                                    <Link to={process.env.PUBLIC_URL +"/Parciales/"+ data.courseLink}>
                                        <div className="course-image"
                                             style={{backgroundImage: `url(${data.imgUrl})`}}>
                                            <div className="author-img d-flex">
                                                <div className="img">
                                                    <img
                                                        src={process.env.PUBLIC_URL + `/assets/images/${data.authorImg}`}
                                                        alt=""/>
                                                </div>
                                                <div className="title">
                                                    <p>{data.authorName}</p>
                                                    <span>{data.authorCourses}</span>
                                                </div>
                                            </div>

                                        </div>
                                    </Link>
                                    <div className="course-content">
                                        <h6 className="heading"><Link
                                            to={process.env.PUBLIC_URL +"/Parciales/"+ data.courseLink}>{data.courseTitle}</Link></h6>
                                        <p className="desc">{data.courseDesc}</p>
                                        <div className="course-face d-flex justify-content-between">

                                            <div className="rating">
                                                <ul className="list-unstyled list-inline">
                                                    {this.stars(data.stars)}
                                                    <li className="list-inline-item">({data.stars})</li>
                                                </ul>
                                            </div>
                                            <div className="student">
                                                <p><i className="las la-chair"></i>{data.studentCount}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        )
                    }
                )
                }



            </Fragment>
        )
    }
}

export default CourseItemGrid
