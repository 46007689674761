import React, {Component} from 'react';
import {Button, Container, Row, Col, Tab, Nav} from 'react-bootstrap';
import HeaderTwo from '../../components/HeaderTwo';
import {BreadcrumbBox} from '../../components/common/Breadcrumb';

import FooterTwo from '../../components/FooterTwo';
import {Styles} from './styles/course.js';
import {firestore, storage} from "../../components/firebase/fbApp";
import ReactLoading from "react-loading";
import {Link} from "react-router-dom";

import "../../../node_modules/video-react/dist/video-react.css";


const getUrl = async (img) => {
    await img
    try {
        const url = storage.ref().child(img).getDownloadURL()
        return await url
    } catch (error) {
        console.log(error)
        return (process.env.PUBLIC_URL + '/assets/images/about-1.jpg')
    }

}

const getVideo = async (video) => {
    await video

    return video

}

const getContent = async (materia, parcial) => {

    const data = await firestore.collection('parciales').doc((materia + "-" + parcial)).collection("contenido").get()
    let content = []
    console.log("data", data)

    data.docs.sort((item1, item2) => {
        return (item1.data().orden - item2.data().orden)
    }).map((item) => {
        let tmp = {
            titulo: item.id,
            video: item.data().video,
            orden: item.data().orden,
            tiempo: item.data().tiempo,
            quiz: item.data().quiz,
        }
        console.log("item.data().quiz", item.data().quiz)

        content.push(tmp)
        return tmp

    })
    return content

}

const getArchivos = async (materia, parcial) => {
    console.log("ENTROOOOOOO")
    const data = await firestore.collection('parciales').doc((materia + "-" + parcial)).collection("archivos").get()
    let content = []
    //console.log("data", data)
    if(!data.empty){
        data.forEach(doc => {
            content.push(
                doc.data().archivo
            )
            console.log(doc.data().archivo)
        })
       
    }else{
        console.log("asdfgsafgdfgsdfgas")
    }
    return content;
}

const getCoursesDetails = async (handle, handle2) => {

    try {
        const data = await firestore.collection('cursos').where('courseLink', '==', handle).get()

        let parciales = []

        for (let item in data.docs[0].data()["Parciales"]) {
            if (data.docs[0].data()["Parciales"][item]["productUrl"] === handle2) {
                await getUrl(data.docs[0].data()["Parciales"][item].imgURL)
                    .then((url) => {
                            const tmp = {
                                id: data.docs[0].data()["Parciales"][item].id,
                                productImg: url,
                                productTitle: data.docs[0].data()["Parciales"][item].Titulo,
                                productUrl: data.docs[0].data()["Parciales"][item].productUrl,
                                price: data.docs[0].data()["Parciales"][item].price,
                                longTitle: data.docs[0].data()["Parciales"][item].longTitle,
                                tutor: data.docs[0].data().authorName,
                                categoria: data.docs[0].data().category,
                                stars: data.docs[0].data().stars,
                                descripcion: data.docs[0].data()["Parciales"][item].descripcion,
                                DuracionDeVideos: data.docs[0].data()["Parciales"][item].DuracionDeVideos,
                                Videos: data.docs[0].data()["Parciales"][item].Videos,
                                Quizes: data.docs[0].data()["Parciales"][item].Quizes,
                                ExamenesResueltos: data.docs[0].data()["Parciales"][item].ExamenesResueltos,
                                curriculumOverview: data.docs[0].data()["Parciales"][item].curriculumOverview,

                            }
                            parciales.push(tmp)
                        }
                    )
            }
        }
        return parciales[0]

    } catch (error) {
    }
}

const hasUserPermisions = async (parcial, videoNum) => {
    var uid = localStorage.getItem("Verification")
    //var uid ="x9kCmW5MrJb37BdhviDsAHxi9X02"
    let desde = 0;
    let hasta = 0;
    console.log(uid)
    console.log(videoNum, typeof videoNum)
    const resp = await fetch("https://us-central1-agora-plus.cloudfunctions.net/api/getFechas")
        .then(res => {
        return res.json().then(body => {
            console.log(body)
            return body
        })
        }).catch(e => {
            console.log(e)
        })

    console.log(resp)
    desde = resp.inicio
    hasta = resp.fin

    if (uid != null) {
        const userRef = firestore.collection('users').doc(uid);
        const snapshot = await userRef.get();
        console.log("snapshot", snapshot.data())
        try {
            const date = snapshot.data()["comprado"]["data"][parcial];
            console.log("date", date, typeof (date))
            if (typeof (date) != "undefined") {
                if (date < hasta && date > desde) {
                    return true
                } else {
                    console.log((date < hasta), (date > desde))
                    return false
                }

            } else {
                console.log("undefined date")
                return false
            }
        } catch (e) {
            console.log(e)
        }


    } else {
        return false

    }


}

class CourseVideo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {},
            loading: true,
            curriculum: [],
            video: "",
            error: false,
            permission: false,
            handles: this.props.match.params,
            archivos: [],
            arch: false
        }
    }


    componentDidMount() {
        const {handle, handle2, handle3} = this.props.match.params
        console.log("comònent did mount")

        hasUserPermisions((handle + "-" + handle2), handle3).then((permission) => {
            console.log("permission", permission)
            if (permission) {
                getCoursesDetails(handle, handle2).then((res) => {
                    this.setState(
                        {
                            data: res,
                        }
                    )
                }).catch((e) => {
                    console.log(e)
                    this.setState({
                        error: true,
                        loading: false,

                    })
                })

                getContent(handle, handle2).then((res) => {
                    getVideo(res[(parseInt(handle3) - 1)].video).then((video) => {
                            this.setState({
                                video: video,
                                curriculum: res,
                                loading: false,
                                permission: true,
                            })

                        }
                    )

                }).catch((e) => {
                    console.log(e)
                    this.setState({
                        error: true,
                        loading: false,
                    })
                })

                getArchivos(handle, handle2).then((res) => {
                    if(res.length > 0){
                        this.setState({
                            archivos: res,
                            arch: true
                        })
                    }else{
                        this.setState({
                            archivos: [],
                            arch: false
                        })
                    }

                }).catch((e) => {
                    console.log(e)
                    this.setState({
                        error: true,
                        loading: false,
                    })
                })
            } else {
                this.setState({
                    error: false,
                    loading: false,
                    permission: false
                })
            }
        })


        const courseButton = document.querySelectorAll(".course-button");
        courseButton.forEach(button => {
            button.addEventListener("click", () => {
                button.classList.toggle("active");
                const content = button.nextElementSibling;

                if (button.classList.contains("active")) {
                    content.className = "course-content show";
                    content.style.maxHeight = content.scrollHeight + "px";
                } else {
                    content.className = "course-content";
                    content.style.maxHeight = "0";
                }
            });
        });
    }


    render() {
        const {handle, handle2, handle3} = this.props.match.params
        console.log("render", handle, handle2, handle3)
        let iframe = (<h1>loading</h1>)
        try {
            console.log("src={this.state.curriculum[handle3 - 1].video}", this.state.curriculum[handle3 - 1].video)
             iframe = (
                <iframe src={this.state.curriculum[handle3 - 1].video}
                        title="Video"
                        style={{
                            border: "0",
                            maxWidth: "100%",
                            position: "absolute"
                            , top: "0",
                            left: "0",
                            height: "100%",
                            width: "100%",
                        }}
                        allowFullScreen="true"
                        allow="encrypted-media"
                        key={handle3 - 1}
                >
                </iframe>
            )
        } catch (e) {
            console.log("no info")
            iframe = (<h1>error</h1>)
        }



        return (
            <div className="main-wrapper course-details-page">

                {/* Header 2 */}
                < HeaderTwo/>

                {/* Breadcroumb */}
                < BreadcrumbBox title={handle + " / " + handle2 + " / " + handle3}/>

                <Styles>
                    {/* Course Details */}
                    <section className="course-details-area">
                        <Container>
                            {!this.state.loading && !this.state.error && !this.state.permission &&
                            (<div>
                                <h1>No tienes permiso de ver este video</h1>
                                <h2> no lo has comprado o no estas logedIn </h2>
                                <p> Si el problema continua contactanos.</p>
                            </div>)
                            }

                            {!this.state.loading && this.state.error && this.state.permission &&
                            (<div>
                                <h1> Intente de Nuevo</h1>
                                <h2>Ocurrio un Error</h2>
                                <p>Si el problema continua contactanos.</p>
                            </div>)
                            }
                            {this.state.loading && !this.state.error &&
                            <ReactLoading type="bubbles" color="#11B67A" height={'10%'} width={'10%'}/>
                            }
                            {!this.state.loading && !this.state.error && this.state.permission && this.state.arch && (
                                <>
                                <Row>
                                    <Button onClick = {event => {
                                        this.state.archivos.forEach((arch, index) => {
                                            storage.ref().child(arch).getDownloadURL()
                                             .then(resp => {
                                                 window.open(resp)
                                             })
                                        })
                                    }}>
                                        Descargar material del curso
                                    </Button>
                                </Row>
                                </>
                            )}
                            {!this.state.loading && !this.state.error && this.state.permission &&
                            (
                                <>
                                <Row>
                                    <Col lg="9" md="8" sm="12">
                                        <div className="course-details-top">
                                            <div className="heading">
                                                <h4>{this.state.data.longTitle}</h4>
                                            </div>
                                            <div className="course-top-overview">
                                                <div className="d-flex overviews">
                                                    <div className="author">
                                                        <div className="author-name">
                                                            <h6>Tema</h6>
                                                            <p>{this.state.curriculum[handle3 - 1].titulo}</p>
                                                        </div>
                                                    </div>
                                                    <div className="category">
                                                        <h6>Video</h6>
                                                        <p>{handle3}/{this.state.curriculum.length}</p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="course-details-banner">

                                                <div style={{paddingTop: "56.25%", position: "relative"}}>
                                                    {iframe}
                                                </div>
                                            </div>
                                            

                                            <div className="course-tab-list">
                                                
                                                <Tab.Container defaultActiveKey="overview">
                                                    <Nav className="flex-column">

                                                        <Nav.Item>
                                                            <Nav.Link eventKey="curriculum">Curriculum</Nav.Link>
                                                        </Nav.Item>
                                                    </Nav>
                                                    <Tab.Content>
                                                        <Tab.Pane eventKey="curriculum" className="curriculum-tab">

                                                            <div className="course-element">
                                                                <h5>Course Content</h5>
                                                                <div className="course-item">
                                                                    <div className="course-content show">
                                                                        <ul className="list-unstyled">
                                                                            {
                                                                                !this.state.loading && this.state.curriculum.map((item) => {
                                                                                    let link = "/course-video/"
                                                                                    if (item.quiz) {
                                                                                        link = "/quiz/"
                                                                                    }

                                                                                    return (
                                                                                        <Link
                                                                                            to={link + handle + '/' + handle2 + '/' + item.orden}
                                                                                            key={item.orden}>
                                                                                            <li key={item.orden}>
                                                                                   <span className="play-icon">
                                                                                       <i className="las la-play"></i>
                                                                                       Tema: {item.orden}
                                                                                   </span>
                                                                                                <span
                                                                                                    className="lecture-title">
                                                                                       {item.titulo}
                                                                                   </span>
                                                                                                <span
                                                                                                    className="lecture-duration">
                                                                                       {item.tiempo}
                                                                                   </span>
                                                                                            </li>
                                                                                        </Link>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Tab.Pane>
                                                    </Tab.Content>
                                                </Tab.Container>
                                            </div>
                                        </div>
                                    </Col>
                                    {!(this.state.curriculum.length === (parseInt(handle3))) &&
                                    (<Col lg="3" md="4" sm="12">
                                            <div className="single-details-sidbar">
                                                <Row>
                                                    <Col md="12">
                                                        <div className="course-details-feature">
                                                            <Link
                                                                to={((this.state.curriculum[parseInt(handle3)].quiz) ? '/quiz/' : '/course-video/')
                                                                + handle + '/' + handle2 + '/'
                                                                + (parseInt(handle3) + 1)}>
                                                                <button type="button" className="enroll-btn">
                                                                    Siguiente Video
                                                                </button>
                                                            </Link>
                                                        </div>
                                                    </Col>

                                                </Row>
                                            </div>
                                        </Col>
                                    )}
                                </Row>
                                </>
                            )
                            }

                        </Container>
                    </section>
                </Styles>

                {/* Footer 2 */}
                <FooterTwo/>

            </div>
        )
    }

}

export default CourseVideo