import React, {Component} from 'react';
import HeaderTwo from '../../components/HeaderTwo';
import FooterTwo from '../../components/FooterTwo';

class PoliticaPrivacidad extends Component {
    render() {

        return (

            <div className="main-wrapper">

                {/* Header 2 */}
                <HeaderTwo/>

                <section style={{margin: "20px" }} >
                    <h1 style={{textAlign: "center" }}><strong>AVISO DE PRIVACIDAD PARA LOS CLIENTES DE ÁGORA PLUS</strong></h1>
                    <br/>
                    <h2 style={{textAlign: "center" }}>ÁMBITO DE APLICACIÓN</h2>
                    <br/>
                    <p>Este aviso se aplica a cualquier Usuario, que se encuentre en cualquier parte del mundo y que
                        utilicen los Servicios de Ágora Plus mediante cualquiera de sus plataformas o directamente
                        en el sitio web www.agoraplus.mx ya sea que intercambie información con la comunidad de Ágora
                        Plus
                        por cualquier medio de interacción como cuentas de redes sociales, correo electrónico,
                        mensajería
                        instantánea, chat privado, por teléfono, personalmente o por que formen parte de alguna
                        institución
                        educativa a la cual la Ágora Plus le preste un servicio independiente.</p>
                    <br/>
                    <h3>1. Responsable de los Datos Personales</h3>
                    <br/>
                    <p>Ágora Plus www.agoraplus.mx , representada por el señor Gabriel Barakat Zacarías. Él será
                        responsable en todo momento del tratamiento de sus Datos Personales y de su protección. Ágora
                        Plus, como
                        responsable del tratamiento de los datos mencionados, garantiza a través de este documento su
                        buen uso,
                        protección y confidencialidad.</p>
                    <br/>
                    <h3>2. Datos Personales que se recaban del Titular y son sometidos a Tratamiento</h3>
                    <br/>
                    <p>Para los fines establecidos en el presente Aviso de Privacidad, Ágora Plus podrá recabar los
                        siguientes “Datos Personales” de usted (el “Titular”):
                        <br/>
                        <br/>
                        <ul>
                            <li>Nombre completo.</li>
                            <li>Correo electrónico.</li>
                            <li>Carrera que está estudiando el usuario.</li>
                            <li>Semestre</li>
                            <li>Contraseña.</li>
                            <li>Teléfono.</li>
                            <li>Nombre de usuario.</li>
                            <li>Foto de perfil.</li>
                            <li>Dirección.</li>
                            <li>Nacionalidad</li>
                        </ul>
                        <br/>
                        Los Datos Personales recabados por Ágora Plus serán tratados para el cumplimiento de los
                        fines
                        identificados en el presente Aviso de Privacidad, para lo cual Ágora Plus requiere su
                        consentimiento
                        de conformidad con lo establecido en el artículo 9º de la Ley Federal de Protección de Datos
                        Personales
                        en Posesión de los Particulares.
                    </p>
                    <br/>
                    <h3>3. Finalidades para las que se recaban los Datos Personales</h3>
                    <br/>
                    <p>Los Datos Personales del Titular solicitados en la sección de “ingreso” o “registro” así como
                        cualquier otro tipo de registro dentro del sitio web de Ágora Plus únicamente serán
                        utilizados para
                        : (i) controlar el acceso al sitio web mencionado; (ii) para establecer comunicación entre
                        el Titular
                        y los administradores de Ágora Plus; (iii) para enviar publicidad y ofertas sobre servicios
                        relacionados
                        con las actividades y servicios de Ágora Plus; (iv) el procesamiento de preguntas de los
                        clientes y la retroalimentación
                        del servicio; y, (v) otros propósitos relacionados al servicio que presta Ágora Plus.</p>
                    <br/>
                    <h3>4. Derechos de Acceso, Rectificación, Cancelación y Oposición (ARCO)</h3>
                    <br/>
                    <p>Usted tiene derecho, en todo momento, de acceder, rectificar y cancelar sus Datos Personales,
                        así
                        como de oponerse al tratamiento de los mismos, limitar su uso y divulgación o revocar el
                        consentimiento
                        que nos otorga por este medio para el Tratamiento de sus Datos Personales (conocidos como
                        “Derechos ARCO”),
                        enviando una solicitud en la que indique cuál o cuáles de estos derechos quiere ejercer al
                        correo electrónico hola@agoraplus.com</p>
                    <br/>
                    <p>La solicitud mencionada en el párrafo anterior deberá contener, al menos, la siguiente
                        información:
                        (i) nombre completo y correo electrónico para comunicarle la respuesta a su solicitud; (ii)
                        copia simple
                        de los documentos que acrediten su identidad; (iii) la descripción clara y precisa de los Datos
                        Personales
                        respecto de los cuales busca ejercer alguno de los Derechos ARCO; y, (iv) cualquier otro
                        elemento o documento
                        que facilite la localización de sus Datos Personales.</p>
                    <br/>
                    <p>Ágora Plus comunicará dentro de los 20 (veinte) días hábiles siguientes a que se reciba la
                        solicitud
                        la respuesta al Titular de los Datos Personales. En caso de que Ágora Plus, de acuerdo con la
                        legislación
                        aplicable, determine que la solicitud resulta aplicable, llevará a cabo los actos necesarios
                        para hacerla
                        efectiva dentro de los 15 (quince) días hábiles siguientes a la fecha en que comunique la
                        respuesta al Titular.
                        La respuesta se dará vía electrónica a la dirección de correo electrónico que el Titular indique
                        en su solicitud.</p>
                    <br/>
                    <p>En caso de que la información proporcionada en la solicitud sea insuficiente o errónea, o bien,
                        no se acompañen los documentos necesarios, dentro de los 5 (cinco) días hábiles siguientes a la
                        recepción de la solicitud, Ágora Plus podrá requerirle que aporte los elementos o documentos
                        necesarios para dar trámite a la misma Usted contará con 10 (diez) días hábiles para atender el
                        requerimiento, contados a partir del día siguiente en que el Titular lo haya recibido. De no dar
                        respuesta en el plazo indicado anteriormente, la solicitud correspondiente se tendrá por no
                        presentada.</p>
                    <br/>
                    <h3> 5. Revocación</h3>
                    <p>Usted puede revocar en cualquier momento el consentimiento que, en su caso, nos haya otorgado
                        para el tratamiento de sus Datos Personales en relación con los fines de tratamiento expuestos
                        en el presente Aviso de Privacidad. Sin embargo, tome en cuenta que no en todos los casos
                        podremos atender su solicitud de revocación o concluir el uso de estos de forma inmediata.
                        Asimismo, usted deberá considerar que, para ciertos fines, la revocación de su consentimiento
                        implicará que Ágora Plus no podrá seguirle prestando su servicio, o bien, puede implicar la
                        conclusión de su relación con Ágora Plus. Usted podrá revocar el cualquier momento su
                        consentimiento siguiendo el mismo procedimiento descrito en el apartado IV del presente
                        documento relacionado con el ejercicio de los Derechos ARCO.</p>
                    <br/>
                    <h3> 6. Derecho de limitar el uso de los Datos Personales</h3>
                    <br/>
                    <p>De igual manera usted, el Titular, podrá ejercer su derecho de limitar el uso o divulgación de su
                        información, de manera enunciativa más no limitativa, para fines de mercadotecnia siguiendo el
                        mismo procedimiento descrito en el apartado IV del presente documento relacionado con el
                        ejercicio de los Derechos ARCO.</p>
                    <br/>
                    <h3>7. Medidas de seguridad</h3>
                    <br/>
                    <p>Ágora Plus cuenta con las medidas de seguridad suficientes para la protección, confidencialidad y
                        aseguramiento de sus datos personales con la finalidad de restringir el acceso a estos a
                        personas no autorizadas. Asimismo, nuestros empleados, representantes, subcontratistas,
                        consultores y/o cualesquiera terceros que intervengan en cualquier fase del tratamiento de sus
                        Datos Personales guardarán confidencialidad respecto a estos y tendrán, al menos, las mismas
                        obligaciones respecto a sus Datos Personales que las establecidas en el presente Aviso de
                        Privacidad. Estas obligaciones subsistirán hasta después de que Ágora Plus finalice la relación
                        con las personas mencionadas.</p>
                    <br/>

                    <h3>8. Modificaciones al Aviso de Privacidad</h3>
                    <br/>
                    <p>El presente Aviso de Privacidad podrá ser cambiado, enmendado o modificado en cualquier momento
                        por Ágora Plus, con la finalidad de atender las novedades legislativas, jurisprudenciales,
                        políticas internas, así como nuevos requerimientos necesarios para la prestación de sus
                        servicios.</p>
                    <br/>
                    <p>Cualquier modificación a este Aviso de Privacidad se le notificará al usuario a través del correo
                        electrónico que le proporcione a Ágora Plus al momento de registrarse en el sitio web.</p>
                    <br/>
                    <h3>9. Consentimiento</h3>
                    <br/>
                    <p>En términos de lo dispuesto en la Ley y su Reglamento, y sin que medie error, mala fe o dolo, el
                        Usuario manifiesta su conformidad con el presente Aviso de Privacidad y su consentimiento para
                        que sus datos personales sean tratados en concordancia con lo expuesto en el mismo.</p>
                    <br/>
                    <p>El presente Aviso de Privacidad no aplicará para todas aquellas entidades o terceros que Ágora
                        Plus no tenga el control sobre ellos. Por lo tanto, Ágora Plus no es responsable de las
                        prácticas de información de cualquiera de estos u otros sitios de terceros; publicidad o enlaces
                        que pudieran aparecer en la página web o app.</p>
                    <br/>
                    <p>La página web y cualquiera de las plataformas de Ágora Plus fueron creadas para usuarios en
                        México, pero es aplicable en cualquier parte del mundo de acuerdo a tratados internacionales y
                        protección de derechos humanos que contemplan el uso de información personal de usuarios. El
                        presente Aviso de Privacidad será interpretado conforme a las leyes de México.</p>
                    <br/>
                    <h3>10. Acceso al Aviso de Privacidad</h3>
                    <br/>
                    <p>Ágora Plus pone a disposición de sus usuarios el presente Aviso de Privacidad a través del sitio web en todo momento.</p>
                    <br/>
                    <p>Al aceptar los de este Aviso de Privacidad, usted manifiesta su consentimiento expreso para que Ágora Plus haga uso y de tratamiento a sus Datos Personales para las finalidades previstas en el mismo.</p>
                    <br/>
                    <p>Si usted tiene algún comentario o duda respecto a este Aviso de Privacidad, contáctenos a hola@agoraplus.com</p>
                    <br/>
                    <p>Última fecha de actualización: 1 de agosto de 2021</p>
                    <br/>
                </section>

                {/* Footer 2 */}
                <FooterTwo/>

            </div>

        )
    }
}

export default PoliticaPrivacidad