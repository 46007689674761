import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import styles from './Question.module.scss';
import {firestore, storage} from "../../../../../../components/firebase/fbApp";
import Progress from './Progress/Progress';
import Alternative from './Alternative/Alternative';

function Question({
  quizTitle,
  numTotalQuestions,
  numCurrentQuestion,
  question,
  onAnswerSelected,
}) {

    const [url, setUrl] = useState('');
    const [lastImg, setLastImg] = useState('');

    console.log("img",question.img)

    if(question.img !== undefined && question.img !== lastImg){
      setLastImg(question.img);
      if(question.img.substring(0,6) === "QWERTY"){
        storage.ref().child(question.img).getDownloadURL()
        .then(resp => {
          setUrl(resp);
          console.log(resp)
        })
      }else{
        setUrl(require(`../../assets/images/quiz/${question.img}`));
      }

    }


    return (
    <div className={styles.question}>
      <div className={styles['quiz-title']}>{quizTitle}</div>

      <Progress
        numCurrentQuestion={numCurrentQuestion}
        numTotalQuestions={numTotalQuestions}
      />
      
      {( question.img !== undefined) && (
        <img
          alt="Current question"
          src={url}
          className={styles['question-img']}
        />
      )}

      <div className={styles['question-text']}>
        <strong>{question.text}</strong>
      </div>

      <div className={styles.alternatives}>
        {question.alternatives.map(alternative => (
          <Alternative
            alternative={alternative}
            isQuestionAnswered={question.isAnswered}
            key={`alternative-${question.id}-${alternative.id}`}
            onAnswerSelected={onAnswerSelected}
          />
        ))}
      </div>
    </div>
  );
}

Question.propTypes = {
  quizTitle: PropTypes.string.isRequired,
  numTotalQuestions: PropTypes.number.isRequired,
  numCurrentQuestion: PropTypes.number.isRequired,
  question: PropTypes.shape({
    id: PropTypes.number.isRequired,
    text: PropTypes.string.isRequired,
    img: PropTypes.string,
    isAnswered: PropTypes.bool.isRequired,
    alternatives: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        text: PropTypes.string.isRequired,
        img: PropTypes.string,
      }).isRequired,
    ).isRequired,
  }).isRequired,
  onAnswerSelected: PropTypes.func.isRequired,
};

export default Question;
