import React, { useEffect,useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import HeaderTwo from '../../components/HeaderTwo';
import { BreadcrumbBox } from '../../components/common/Breadcrumb';
import FooterTwo from '../../components/FooterTwo';
import { Styles } from './styles/account.js';
import firebase from 'firebase/app'
import {Link} from "react-router-dom";
import {auth, generateUserDocument, getUserDocument} from "../../components/firebase/fbApp";



function Login(props) {

    const [error, setError] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");


    const verifyDevice=(id)=>{
        var local = localStorage.getItem("Verification")
        if (local===id){
            console.log("success verificaton")
            return true
        }
        else{
            console.log("error in verification")
            setError(true)
            setErrorMsg("You are not authorized to log in in this device or browser, if there has been an error contact support")
            throw new Error("You are not authorized to log in in this device or browser, if there has been an error contact support or you havent created a user")

        }
    }

    const setVerifyDevice = (id) => {
        localStorage.setItem("Verification", id);
        console.log("set", id)
    }

    const signInWithFacebook=()=>{
        var provider = new firebase.auth.FacebookAuthProvider();
        firebase
            .auth()
            .signInWithPopup(provider)
            .then((result) => {
                /** @type {firebase.auth.OAuthCredential} */
                var credential = result.credential;

                // The signed-in user info.
                var user = result.user;

                // This gives you a Facebook Access Token. You can use it to access the Facebook API.
                var accessToken = credential.accessToken;

                console.log(user)

                console.log(user,accessToken)
                  try {
                      verifyDevice(user.uid)
                      window.location = "/"
                  }
                  catch (e){
                      getUserDocument(user.uid).then((res)=>{
                          console.log("res de get user doc ",res)
                          if(typeof(res.dateCreated)==="undefined"){
                              const date = Date.now();
                              generateUserDocument(user, user.uid,date).then((userCreated)=>{
                                  try{
                                      console.log("test new user",user.uid,userCreated,userCreated.dateCreated,date)
                                      if(userCreated.dateCreated===date){
                                          console.log("yes verify")
                                          setVerifyDevice(user.uid)
                                          setError(false)
                                          window.location = "/"
                                      }
                                  }
                                  catch (e){
                                      console.log("not set verify",e)
                                  }
                              })
                          }
                          else if (res.resetCookie !== undefined){
                              console.log("user.resetCookie",res.resetCookie)
                              if(res.resetCookie){
                                  setVerifyDevice(user.uid)
                                  setError(false)
                              }
                          }
                      })

                  }


                // ...
            })
            .catch((error) => {
                // Handle Errors here.
                var errorCode = error.code;
                var errorMessage = error.message;
                // The email of the user's account used.
                var email = error.email;
                // The firebase.auth.AuthCredential type that was used.
                var credential = error.credential;

                console.log(errorCode,errorMessage,email,credential)
                setError(true)
                setErrorMsg(errorMessage)
                auth.signOut()

                // ...
            })
    }



    useEffect(() => {

    });

    return (
        <Styles>
            {/* Main Wrapper */}
            <div className="main-wrapper registration-page">

                {/* Header 2 */}
                <HeaderTwo />

                {/* Breadcroumb */}
                <BreadcrumbBox title="Log In" />

                {/* Login Area */}
                <section className="registration-area">
                    <Container>
                        <Row>
                            <Col md="12">
                                <div className="registration-box">
                                    <div className="registration-title text-center">
                                        <h3>Log In</h3>
                                    </div>

                                    <div className="social-login text-center">

                                        <ul className="list-unstyled list-inline">
                                            <li onClick={signInWithFacebook} className="list-inline-item facebookButton"><i className="fab fa-facebook-f"></i> Login with Facebook</li>
                                        </ul>
                                    </div>
                                    <div className="have_account-btn text-center">
                                        <p>Dont have an account? <Link to="/registration">Register Here</Link></p>
                                        <p> <Link to="/loginmail">Log In Email</Link></p>


                                    </div>

                                    {error && (<p style={{color:"red"}}>{errorMsg}</p>)}

                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>

                {/* Footer 2 */}
                <FooterTwo />

            </div>
        </Styles>
    )
}

export default Login