import React, { useState, useEffect } from "react";
import {
    CardElement,
    useStripe,
    useElements
} from "@stripe/react-stripe-js";
import {Container, 
        Row, 
        Col, 
        Card, 
        Button} from 'react-bootstrap';

import axios from 'axios';
import {firestore} from "../components/firebase/fbApp";
import ReactLoading from 'react-loading';


function OxxoPayForm(props){

    const [succeeded, setSucceeded] = useState(false);
    const [error, setError] = useState(false);
    const [txtErr, setTxtErr] = useState('')
    const [processing, setProcessing] = useState('');
    const [disabled, setDisabled] = useState(true);
    const [clientSecret, setClientSecret] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [price, setPrice] = useState('');
    const stripe = useStripe();
    const elements = useElements();

    const [loading, setLoading] = useState(false);

    const [user, setUser] = useState('');

    useEffect(() => {
        //Lo necesario para sacar un clientSecret
        setPrice((props.price*100).toString())
        setUser(props.user)
        console.log('Usuario: ', props.user)
        
    
    }, [])

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        console.log('caca');
        const body = {
            price: price
        }
        //https://us-central1-agora-plus.cloudfunctions.net
        //http://localhost:5000/agora-plus/us-central1
        fetch("https://us-central1-agora-plus.cloudfunctions.net/createPaymentIntentOxxo", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Origin": "https://admin-agoraplus.netlify.app",
                    "Access-Control-Request-Method": "POST",
                    "Access-Control-Request-Headers": "Content-Type",
                    "Access-Control-Allow-Origin": "*"

                },
                body: JSON.stringify({price: price})
            
            }).then(res => {
                return res.json();
            }).then(function(responseJson) {
                console.log("Respuesta: ", responseJson);
                var clientSecret = responseJson.clientSecret;
                console.log(clientSecret);
                let id = ""
                if(props.user.id !== undefined){
                    id = props.user.id
                }else{
                    id = props.user.uid
                }

                fetch("https://us-central1-agora-plus.cloudfunctions.net/api/creaIntent", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "Origin": "https://admin-agoraplus.netlify.app",
                        "Access-Control-Request-Method": "POST",
                        "Access-Control-Request-Headers": "Content-Type",
                        "Access-Control-Allow-Origin": "*"

                    },
                    body: JSON.stringify({
                        price: price,
                        name: name,
                        email: email,
                        idIntent: clientSecret,
                        curso: props.parcial,
                        userID: id
                    })
                
                }).then(res => {
                    return res.json();
                })

                
                stripe.confirmOxxoPayment(
                    clientSecret,
                    {
                    payment_method: {
                        billing_details: {
                        name: name,
                        email: email,
                        },
                    },
                    }) // Stripe.js will open a modal to display the OXXO voucher to your customer
                    .then(function(result) {
                    // This promise resolves when the customer closes the modal
                    if (result.error) {
                        // Display error to your customer
                        //var errorMsg = document.getElementById('error-message');
                        //errorMsg.innerText = result.error.message;
                        console.log("Error: ", result.error.message)
                        setTxtErr(result.error.message)
                        setError(true)
                        console.log(result)
                    }
                });
            });
    }

    const cardStyle = {
        style: {
            base: {
                color: "#32325d",
                fontFamily: 'Arial, sans-serif',
                fontSmoothing: "antialiased",
                fontSize: "16px",
                "::placeholder": {
                    color: "#32325d"
                }
            },
            invalid: {
                color: "#fa755a",
                iconColor: "#fa755a"
            }
        }
    };

    

    return(
    <>
        
            <Col>
                <form id="payment-form" style={{width:"100%"}} onSubmit={handleSubmit}>
                    <h3>Pago en Oxxo</h3>
                    <br/>
                    <div>
                        <div class="sr-combo-inputs" id="oxxo-billing-details">
                        <div>
                            <label for="name">
                            Nombre completo
                            </label>
                            <input 
                                id="name" 
                                name="name" 
                                placeholder="" 
                                required
                                onChange={(e) => setName(e.target.value)}  />
                        </div>
                        <div>
                            <label for="email">
                            Correo Electrónico
                            </label>
                            <input
                            id="email"
                            name="email"
                            type="email"
                            placeholder=""
                            onChange={(e) => setEmail(e.target.value)}
                            required
                            />
                            {email}
                            {name}
                        </div>
                        </div>
                    </div>
                    <button id="submit" type="submit" disabled={loading}>
                        <div class="spinner hidden" id="spinner"></div>
                        <span id="button-text">Pago en Oxxo</span><span id="order-amount"></span>
                    </button>
                    {!loading ? (<h3><b>NOTA: </b> Oxxo refleja sus pagos 24hrs hábiles después, por ello podrás tener acceso a tu material una vez que Oxxo nos notifique del pago</h3>):(<></>)}
                    {loading ? (<h3>Estamos generando el voucher de pago, no cierres la ventana</h3>):(<></>)}
                    {loading ? (<h3>El proceso puede tardar hasta 1 minuto</h3>):(<></>)}
                    {loading ? (<ReactLoading type="bubbles" color="#11B67A" height={'10%'} width={'10%'} />):(<></>)}
                    {error ? (
                        <div className="card-error" role="alert">
                            {txtErr}
                        </div>
                    ): (<></>)}
                </form>
            </Col>

    </>
    );

}

export default OxxoPayForm;
