import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import BackToTop from './common/BackToTop';
import { Styles } from "./styles/footerOne.js";

class Footer extends Component {
    render() {
        return (
            <Styles>
                {/* Footer Area */}
                <footer className="footer1">
                    <Container>
                        <Row>
                            <Col md="4">
                                <div className="footer-logo-info">
                                    <img src={process.env.PUBLIC_URL + "/assets/images/logo/a_logo_white.png"} alt="" className="img-fluid" />
                                    <p>Ágora nace de estudiantes para estudiantes que se toparon con la necesidad de
                                        entender sus materias, lamentablemente los métodos de enseñanza no sirven para el
                                        100% de los alumnos, ya que no todos comprenden de la misma manera, por ello desarrollamos
                                        metodos personalizados para que cada quien aprenda a su ritmo </p>
                                    <ul className="list-unstyled">
                                        <li><i className="las la-map-marker"></i>Puebla #18, col. Progreso Tizapán, CDMX</li>
                                        <li><i className="las la-envelope"></i>hola@agoraplus.mx</li>
                                    </ul>
                                </div>
                            </Col>
                            <Col md="5 offset-3">
                                <div className="f-links">
                                    <h5>Links Utiles</h5>
                                    <ul className="list-unstyled">
                                        <li><Link to={process.env.PUBLIC_URL + "/"}><i className="las la-angle-right"></i>Inicio</Link></li>
                                        <li><Link to={process.env.PUBLIC_URL + "/about"}><i className="las la-angle-right"></i>Nosotros</Link></li>
                                        <li><Link to={process.env.PUBLIC_URL + "/course-grid"}><i className="las la-angle-right"></i>Cursos</Link></li>
                                        <li><Link to={process.env.PUBLIC_URL + "/contact"}><i className="las la-angle-right"></i>Dar una clase</Link></li>
                                        <li><Link to={process.env.PUBLIC_URL + "/politica-de-privacidad"}><i className="las la-angle-right"></i>Privacy Policy</Link></li>
                                        <li><Link to={process.env.PUBLIC_URL + "/terminos-y-condiciones"}><i className="las la-angle-right"></i>Términos y Condiciones</Link></li>
                                    </ul>

                                </div>
                            </Col>

                        </Row>
                    </Container>
                </footer>

                {/* Copyright Area */}
                <section className="copyright-area">
                    <Container>
                        <Row>
                            <Col md="6">
                                <div className="copy-text">
                                    <p>Copyright &copy; {(new Date().getFullYear())} | Built by <a href="https://pointech.dev" target="_blank" rel="noopener noreferrer">Point.</a></p>
                                </div>
                            </Col>
                            <Col md="6" className="text-right">
                                <ul className="social list-unstyled list-inline">
                                    <li className="list-inline-item"><a href={"https://www.facebook.com/agorizate/"}><i className="fab fa-facebook-f"></i></a></li>
                                    <li className="list-inline-item"><a href={"https://www.instagram.com/agorizate/"}><i className="fab fa-instagram"></i></a></li>
                                </ul>
                            </Col>
                        </Row>
                    </Container>

                    {/* Back To Top */}
                    <BackToTop/>
                </section>
            </Styles>
        )
    }
}

export default Footer
