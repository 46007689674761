import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';

import StickyMenu from './common/StickyMenu';
import MobileMenu from './common/MobileMenu';
import { Styles } from "./styles/headerTwo.js";
import User from "./common/User";

class HeaderTwo extends Component {
    render() {

        return (
            <Styles>
                {/* Topbar 2 */}
                <section className="top-bar2">
                    <Container>
                        <Row>
                            <Col lg="7" md="9">
                                <div className="bar-left">
                                    <ul className="list-unstyled list-inline">
                                        <li className="list-inline-item"><i className="las la-envelope"></i>hola@agoraplus.mx</li>
                                        <li className="list-inline-item"><i className="las la-map-marker"></i>Puebla #18, col. Progreso Tizapán, CDMX
                                    </li>
                                    </ul>
                                </div>
                            </Col>
                            <Col lg="4" md="5">
                                <User />
                            </Col>
                        </Row>
                    </Container>
                </section>

                {/* Logo Area 2 */}
                <section className="logo-area2">
                    <Container>
                        <Row>
                            <Col md="3">
                                <div className="logo">
                                    <Link to={process.env.PUBLIC_URL + "/"}><img src={process.env.PUBLIC_URL + "/assets/images/logo/agora_beta.png"} alt="" /></Link>
                                </div>
                            </Col>
                            <Col md="9">
                                <div className="menu-box d-flex justify-content-end">
                                    <ul className="nav menu-nav">
                                        <li className="nav-item active">
                                            <Link className="nav-link dropdown-toggle" to={process.env.PUBLIC_URL + "/"} >Inicio <i className="las "></i></Link>

                                        </li>

                                        <li className="nav-item ">
                                            <Link className="nav-link dropdown-toggle" to={process.env.PUBLIC_URL + "/course-grid"} >Cursos <i className="las "></i></Link>
                                        </li>

                                        <li className="nav-item dropdown">
                                            <Link className="nav-link " to={process.env.PUBLIC_URL + "/about"} >Nosotros <i className="las "></i></Link>
                                        </li>
                                        <li className="nav-item dropdown">
                                            <Link className="nav-link " to={process.env.PUBLIC_URL + "/contact"} >Dar una clase <i className="las "></i></Link>
                                        </li>
                                    </ul>


                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>

                {/* Sticky Menu */}
                <StickyMenu />

                {/* Mobile Menu */}
                <MobileMenu />
            </Styles>
        )
    }
}

export default HeaderTwo
