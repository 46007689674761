import React from 'react'





export default class TakeMoney extends React.Component {


    render() {
        return (

               <p>hi</p>


        )
    }
}