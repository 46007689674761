// Firebase app is always required and must be first
import firebase from 'firebase/app'
//import admin from 'firebase-admin'


// Add additional services that you want to use
import 'firebase/auth'
import 'firebase/database'
import 'firebase/storage'
import 'firebase/firestore'
import 'firebase/messaging'
import 'firebase/functions'

//admin.initializeApp();


const config = {
    apiKey: "AIzaSyBqQqw1WUkH12A43MAj7OA8pIfHTjSQqFM",
    authDomain: "agora-plus.firebaseapp.com",
    projectId: "agora-plus",
    storageBucket: "agora-plus.appspot.com",
    messagingSenderId: "266925526277",
    appId: "1:266925526277:web:fae41d4e355fddc4b5bb6c",
    measurementId: "G-LN8V2490SB"
};


const fbApp = firebase.initializeApp(config);

export default fbApp

export const auth = firebase.auth();

const provider = new firebase.auth.GoogleAuthProvider();

export const signInWithGoogle = () => {
    auth.signInWithPopup(provider);
};

export const firestore = firebase.firestore();

export const generateUserDocument = async (user,id,dateCreated) => {
    if (!user) return;
    console.log("user generateUserDocument",user)
    const userRef = firestore.doc(`users/${user.uid}`);
    const snapshot = await userRef.get();
    if (!snapshot.exists) {
        const {email,displayName,photoURL} = user;
        try {
            await userRef.set({
                id,
                photoURL,
                displayName,
                email,
                dateCreated
            });
        } catch (error) {
            console.error("Error creating user document", error);
        }
    }
    return getUserDocument(user.uid);
};

export const getUserDocument = async uid => {
    if (!uid) return null;
    try {
        const userDocument = await firestore.doc(`users/${uid}`).get();
        return {
            uid,
            ...userDocument.data()
        };
    } catch (error) {
        console.error("Error fetching user", error);
    }
};

export const storage = firebase.storage();

export const CloudFunctions = firebase.functions();




