import React, {useEffect, useRef, useState} from 'react';
import HeaderTwo from "../../components/HeaderTwo";
import {BreadcrumbBox} from "../../components/common/Breadcrumb";
import {Col, Container, Nav, Row, Tab} from "react-bootstrap";
import ReactLoading from "react-loading";
import {Link} from "react-router-dom";
import FooterTwo from "../../components/FooterTwo";
import {firestore, storage} from "../../components/firebase/fbApp";
import { Styles as Styles2 }   from '../courses/styles/course' ;
import Quiz from "./components/Quiz/Quiz";




export default function Quiz1(props) {
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false)
    const data = useRef([]);
    const [curriculum,setCurriculum] = useState([])
    const [quizComponent,setQuizComponent] = useState(<></>)
    const [handleT, setHandleT] = useState(props.match.params)


    const getUrl = async (img) => {
        await img
        try {
            const url =   storage.ref().child(img).getDownloadURL()
            return await url
        } catch (error) {
            console.log(error)
            return (process.env.PUBLIC_URL + '/assets/images/about-1.jpg')
        }

    }

    const getContent =  async (materia,parcial) => {
        const data = await firestore.collection('parciales').doc((materia+"-"+parcial)).collection("contenido").get()
        let content=[]
        data.docs.sort((item1,item2)=>{
            return (item1.data().orden - item2.data().orden)
        }).map((item)=>{
            if(item.data().quiz){
                console.log("item.data().quizData", item.data().quizData.questions)
            }
            let tmp={
                titulo:item.id,
                video:item.data().video,
                orden: item.data().orden,
                tiempo: item.data().tiempo,
                quizData: item.data().quizData,
                quiz: item.data().quiz,
            }
            content.push(tmp)
            return tmp

        })

        setQuizComponent(<Quiz quizData={content[(parseInt(handle3)-1)].quizData}  key={handle3}/>)

        return content

    }
    const getCoursesDetails = async (handle,handle2) => {
        try {
            const data = await firestore.collection('cursos').where('courseLink', '==', handle).get()
            let parciales = []
            for(let item in data.docs[0].data()["Parciales"]){
                if(data.docs[0].data()["Parciales"][item]["productUrl"] === handle2) {
                    await getUrl(data.docs[0].data()["Parciales"][item].imgURL)
                        .then((url) => {
                                const tmp = {
                                    id: data.docs[0].data()["Parciales"][item].id,
                                    productImg: url,
                                    productTitle: data.docs[0].data()["Parciales"][item].Titulo,
                                    productUrl: data.docs[0].data()["Parciales"][item].productUrl,
                                    price: data.docs[0].data()["Parciales"][item].price,
                                    longTitle: data.docs[0].data()["Parciales"][item].longTitle,
                                    tutor: data.docs[0].data().authorName,
                                    categoria: data.docs[0].data().category,
                                    stars:data.docs[0].data().stars,
                                    descripcion: data.docs[0].data()["Parciales"][item].descripcion,
                                    DuracionDeVideos: data.docs[0].data()["Parciales"][item].DuracionDeVideos,
                                    Videos: data.docs[0].data()["Parciales"][item].Videos,
                                    Quizes: data.docs[0].data()["Parciales"][item].Quizes,
                                    ExamenesResueltos: data.docs[0].data()["Parciales"][item].ExamenesResueltos,
                                    curriculumOverview: data.docs[0].data()["Parciales"][item].curriculumOverview,

                                }
                                parciales.push(tmp)
                            }
                        )
                }
            }
            return parciales[0]

        } catch (error) {
        }
    }

    useEffect(()=>{
        console.log("useEfect")
        let {handle,handle2,handle3} = props.match.params
        console.log(handle3)
        if(loading || (handleT !== props.match.params)){
            setHandleT(props.match.params)
            console.log("loading",loading)
            getCoursesDetails(handle,handle2).then( (res) => {
                    data.current = res
            }).catch((e)=>{
                console.log("getCoursesDetails",e)
                setError(true)

            })
            getContent(handle,handle2).then((res,quiz)=>{

                setCurriculum(res)
                setLoading(false)

            }).catch((e)=>{
                console.log("getContent",e)
                setError(true)
                setLoading(false)
            })
        }

        const courseButton = document.querySelectorAll(".course-button");
        courseButton.forEach(button => {
            button.addEventListener("click", () => {
                button.classList.toggle("active");
                const content = button.nextElementSibling;

                if (button.classList.contains("active")) {
                    content.className = "course-content show";
                    content.style.maxHeight = content.scrollHeight + "px";
                } else {
                    content.className = "course-content";
                    content.style.maxHeight = "0";
                }
            });
        });
        }
    )

    const {handle, handle2, handle3} = props.match.params

       let  Mydata = data.current
    return (
        <div className="main-wrapper course-details-page" >

            {/* Header 2 */}
            < HeaderTwo />

            {/* Breadcroumb */}
            < BreadcrumbBox title={"quiz"} />
            <Styles2>
                {/* Course Details */}
                <section className="course-details-area">
                    <Container>
                        {!loading && error &&
                        (<div>
                            <h1 style={{color:"#000000"}}> Intente de Nuevo</h1>
                            <h2 style={{color:"#000000"}}>Ocurrio un Error</h2>
                            <p style={{color:"#000000"}}>Si el problema continua contactanos.</p>
                        </div>)
                        }
                        {loading && !error &&
                        <ReactLoading type="bubbles" color="#11B67A" height={'10%'} width={'10%'} />
                        }
                        {!loading && !error &&
                        (
                            <Row>
                                <Col lg="9" md="8" sm="12">
                                    <div className="course-details-top">
                                        <div className="heading">
                                            <h4>{Mydata.longTitle}</h4>
                                        </div>
                                        <div className="course-top-overview">
                                            <div className="d-flex overviews">
                                                <div className="author">
                                                    <div className="author-name">
                                                        <h6>Tema</h6>
                                                    </div>
                                                </div>
                                                <div className="category">
                                                    <h6>Quiz</h6>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="course-details-banner">
                                            {quizComponent}
                                        </div>

                                        <div className="course-tab-list">
                                            <Tab.Container defaultActiveKey="overview">
                                                <Nav className="flex-column">

                                                    <Nav.Item>
                                                        <Nav.Link eventKey="curriculum">Curriculum</Nav.Link>
                                                    </Nav.Item>
                                                </Nav>
                                                <Tab.Content>
                                                    <Tab.Pane eventKey="curriculum" className="curriculum-tab">
                                                        <div className="course-curriculum">
                                                            <h5>Course Curriculum</h5>
                                                            <p>
                                                                {Mydata.curriculumOverview}
                                                            </p>
                                                        </div>
                                                        <div className="course-element">
                                                            <h5>Course Content</h5>
                                                            <div className="course-item">
                                                                <div className="course-content show">
                                                                    <ul className="list-unstyled">
                                                                        {
                                                                            !loading && curriculum.map((item)=>{
                                                                                return (
                                                                                    <Link to={"/"} key={item.orden}>
                                                                                        <li  key={item.orden}>
                                                                                   <span className="play-icon">
                                                                                       <i className="las la-play"></i>
                                                                                       Tema: {item.orden}
                                                                                   </span>
                                                                                            <span className="lecture-title">
                                                                                       {item.titulo}
                                                                                   </span>
                                                                                            <span className="lecture-duration">
                                                                                       {item.tiempo}
                                                                                   </span>
                                                                                        </li>
                                                                                    </Link>
                                                                                )
                                                                            })
                                                                        }
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Tab.Pane>
                                                </Tab.Content>
                                            </Tab.Container>
                                        </div>

                                    </div>
                                </Col>
                                {!(curriculum.length === (parseInt(handle3))) &&
                                (
                                    <Col lg="3" md="4" sm="12">
                                        <div className="single-details-sidbar">
                                            <Row>
                                                <Col md="12">
                                                    <div className="course-details-feature">
                                                        {console.log("curriculum",curriculum,handle3)}
                                                        <Link to={
                                                            ((curriculum[parseInt(handle3)].quiz)? '/quiz/':'/course-video/')
                                                            + handle + '/' + handle2 + '/'
                                                            + (parseInt(handle3) + 1)}>
                                                            <button type="button" className="enroll-btn" >
                                                                Siguiente Video
                                                            </button>
                                                        </Link>
                                                    </div>
                                                </Col>

                                            </Row>
                                        </div>

                                    </Col>
                                )
                                }


                            </Row>
                        )
                        }

                    </Container>

                </section>
            </Styles2>
            {/* Footer 2 */}
            <FooterTwo />

        </div>

    );
}