import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import { Styles } from "./styles/stickyMenu.js";

function StickyMenu() {
    useEffect(() => {
        window.addEventListener("scroll", () => {
            const stickyMenu = document.querySelector(".sticky-menu");

            if (window.scrollY > 160) {
                stickyMenu.classList.add("sticky");
            } else {
                stickyMenu.classList.remove("sticky");
            }
        });
    });

    return (
        <Styles>
            {/* Sticky Menu */}
            <section className="sticky-menu">
                <Container>
                    <Row>
                        <Col md="3">
                            <div className="logo">
                                <Link to={process.env.PUBLIC_URL + "/"}>
                                    <img src={process.env.PUBLIC_URL + "/assets/images/logo/agora_beta.png"} alt="" />
                                </Link>
                            </div>
                        </Col>
                        <Col md="9">
                            <div className="menu-box d-flex justify-content-end">
                                <ul className="nav menu-nav">
                                    <li className="nav-item active">
                                        <Link className="nav-link dropdown-toggle" to={process.env.PUBLIC_URL + "/"} >Inicio <i className="las "></i></Link>

                                    </li>

                                    <li className="nav-item ">
                                        <Link className="nav-link dropdown-toggle" to={process.env.PUBLIC_URL + "/course-grid"} >Cursos <i className="las "></i></Link>
                                    </li>

                                    <li className="nav-item dropdown">
                                        <Link className="nav-link " to={process.env.PUBLIC_URL + "/about"} >Nosotros <i className="las "></i></Link>
                                    </li>
                                    <li className="nav-item dropdown">
                                        <Link className="nav-link " to={process.env.PUBLIC_URL + "/contact"} >Dar una clase <i className="las "></i></Link>
                                    </li>
                                </ul>

                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </Styles>
    )
}

export default StickyMenu